import React, { useEffect } from 'react';
import PurchaseInfoPopUp from "./PurchaseInfoPopUp";
import { useStateIfMounted } from 'use-state-if-mounted';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { Mixpanel } from '../Mixpanel/Mixpanel';
import { cartRemoveAllItems } from '../../redux/cart/cart.action';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { functions } from '../../firebaseConfig.js';
import { httpsCallable } from 'firebase/functions';
import { checkPostCode } from "./postCodeCheck";
import './styles.css';
import LoadingPopUp from "../common/LoadingPopUp";

<script src='https://js.stripe.com/v3/'></script>;

const CARD_OPTIONS = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#555',
            color: '#111',
            fontWeight: 900,
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#555' },
            '::placeholder': { color: '#555' },
        },
        invalid: {
            iconColor: '#f30',
            color: '#f30',
        },
    },
};

// const PaymentForm = ({ customer, customerDetailsAreAdded, cartList, cartTotal, deliveryDate, secondDeliveryDate, cartRemoveAllItems }) => {
const PaymentForm = ({ customer, customerDetailsAreAdded, cartTotal, cartRemoveAllItems }) => {
    const [success, setSuccess] = useStateIfMounted(false);
    const [isLoadingPopUpShown, setIsLoadingPopUpShown] = useStateIfMounted(false);
    const [isPurchaseInfoPopUpShown, setIsPurchaseInfoPopUpShown] = useStateIfMounted(false);
    const [serverMessage, setServerMessage] = useStateIfMounted('');

    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paymentIntent = queryParams.get('payment_intent');
        const clientSecret = queryParams.get('payment_intent_client_secret');
        const paymentMethodId = localStorage.getItem('paymentMethodId');

        if (paymentIntent && clientSecret) {
            console.log('Payment Intent:', paymentIntent);
            console.log('Client Secret:', clientSecret);
            console.log('Payment Method ID', paymentMethodId);

            confirmPaymentStatus(paymentIntent, clientSecret, paymentMethodId);
        }
    }, [location]);

    function confirmPaymentStatus(paymentIntent, clientSecret, paymentMethodId) {
        setServerMessage('Confirming payment...');
        setIsLoadingPopUpShown(true);

        const confirmPurchase = httpsCallable(functions, 'confirmPurchase');

        confirmPurchase({
            paymentIntent: paymentIntent,
            clientSecret: clientSecret,
            paymentMethodId: paymentMethodId,
        })
            .then((response) => {
                const data = response.data;
                console.log(data);

                setServerMessage(data.orderNumber);
                setSuccess(true);
                setIsLoadingPopUpShown(false);
                setIsPurchaseInfoPopUpShown(true);
                localStorage.removeItem('paymentMethodId');
            })
            .catch((error) => {
                const errorMessage = error?.message ?? 'payment confirmation error';
                console.log(errorMessage);

                setServerMessage(errorMessage);
                setSuccess(false);
                setIsLoadingPopUpShown(false);
                setIsPurchaseInfoPopUpShown(true);
                localStorage.removeItem('paymentMethodId');
            });
    }

    function redirectToURL(url) {
        window.location.href = url;
    }

    const handleSubmit = async (e) => {
        setIsLoadingPopUpShown(true);
        e.preventDefault();

        Mixpanel.track('Button Tapped: Purchase meal plan(s)', {
            totalPrice: (cartTotal / 100).toString(),
            page: 'Cart',
        });

        // CHECK IF POST CODE IS IN SUPPORTED DELIVERY REGION
        const { postCodeError, isPostCodeSupported } = await checkPostCode(customer.postCode);

        // CHECK IF CUSTOMER DETAILS ARE MISSING OR IF POST CODE IS NOT SUPPORTED
        if (!customerDetailsAreAdded || !isPostCodeSupported) {
            const validationErrorMessage =
                postCodeError ??
                'One or more mandatory fields is empty or the emails do not match. Please double check the details you have entered.';

            Mixpanel.track('Operation: Failed to make payment', {
                error: validationErrorMessage,
                page: 'Cart',
            });

            setTimeout(function () {
                setServerMessage(validationErrorMessage);
                setSuccess(false);
                setIsLoadingPopUpShown(false);
                setIsPurchaseInfoPopUpShown(true);
            }, 500);
            e.preventDefault();
            return;
        }

        setServerMessage('Processing payment...');

        // CREATE PAYMENT (CAN FAIL IF CARD IS INCORRECT)
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        // CHECK IF PAYMENT CREATION FAILED
        if (error) {
            const errorMessage = error?.message ?? 'Unknown Error Occurred';

            Mixpanel.track('Operation: Failed to make payment', {
                error: errorMessage,
                page: 'Cart',
            });

            setServerMessage(errorMessage);
            setSuccess(false);
            setIsLoadingPopUpShown(false);
            setIsPurchaseInfoPopUpShown(true);
            return;
        }

        // ATTEMPT FIREBASE PAYMENT
        try {
            const { id } = paymentMethod;
            localStorage.setItem('paymentMethodId', id);
            const totalPriceInCents = parseFloat(cartTotal);
            const purchase = httpsCallable(functions, 'purchase');

            purchase({
                amount: totalPriceInCents,
                paymentMethod: id,
            })
                .then((response) => {
                    const data = response.data;
                    if (data.payment.status === 'succeeded') {
                        Mixpanel.track('Operation: Payment Successful', {
                            orderNumber: data.orderNumber,
                            page: 'Cart',
                        });

                        setServerMessage(data.orderNumber);
                        setSuccess(true);
                        setIsLoadingPopUpShown(false);
                        setIsPurchaseInfoPopUpShown(true);
                    } else if (data.payment.next_action?.redirect_to_url?.url != null) {
                        Mixpanel.track('Operation: Payment 3D Card Verification', {
                            page: 'Cart',
                        });

                        redirectToURL(data.payment.next_action?.redirect_to_url?.url);
                    } else {
                        Mixpanel.track('Operation: Failed to make payment', {
                            error: "Unknown Error Occurred. Server returned 200 but we couldn't handle it on the front-end.",
                            page: 'Cart',
                        });

                        setServerMessage('Unknown Error Occurred');
                        setSuccess(false);
                        setIsLoadingPopUpShown(false);
                        setIsPurchaseInfoPopUpShown(true);
                    }
                })
                .catch((error) => {
                    const errorMessage = error?.message ?? 'Unknown Error Occurred';

                    Mixpanel.track('Operation: Failed to make payment', {
                        error: errorMessage,
                        page: 'Cart',
                    });

                    setServerMessage(errorMessage);
                    setSuccess(false);
                    setIsLoadingPopUpShown(false);
                    setIsPurchaseInfoPopUpShown(true);
                });
        } catch (error) {
            Mixpanel.track('Operation: Failed to make payment', {
                error: 'Unknown Error Occurred',
                page: 'Cart',
            });

            setServerMessage('Unknown Error Occurred');
            setSuccess(false);
            setIsLoadingPopUpShown(false);
            setIsPurchaseInfoPopUpShown(true);
        }

        // ATTEMPT PAYMENT
        // const response = await axios.post(devApi + '/payment', {
        //     customerFirstName: customer.firstName,
        //     customerLastName: customer.lastName,
        //     customerEmail: customer.email,
        //     customerPhoneNumber: customer.phoneNumber,
        //     customerStreetName: customer.streetName,
        //     customerStreetNumber: customer.streetNumber,
        //     customerPostCode: customer.postCode,
        //     customerCity: customer.city,
        //     customerCountry: customer.country,
        //     customerAllergens: customer.allergens == null ? '' : customer.allergens,
        //     customerDeliveryNote: customer.deliveryNote == null ? '' : customer.deliveryNote,
        //     cartItems: cartList,
        //     deliveryDate: deliveryDate,
        //     secondDeliveryDate: secondDeliveryDate,
        //     amount: totalPriceInCents,
        //     id: id,
        // });

        // PAYMENT SUCCESSFUL
        // if (response.data.success) {
        // Mixpanel.track('Operation: PAYMENT SUCCESSFUL', {
        //     serverMessage: response.data.message,
        //     orderNumber: response.data.orderNumber.toString(),
        //     page: 'Cart',
        // });

        //     setServerMessage(response.data.orderNumber.toString());

        //     setSuccess(true);
        //     setIsLoadingPopUpShown(false);
        //     setIsPurchaseInfoPopUpShown(true);

        //     // PAYMENT PROCESSING ERROR
        // } else {
        //     const errorMessage = response.data.message.raw.message;

        //     if (errorMessage != null && typeof errorMessage == 'string') {
        //         Mixpanel.track('Operation: Failed to make payment', {
        //             error: errorMessage,
        //             page: 'Cart',
        //         });
        //         setServerMessage(errorMessage);
        //     } else {
        //         Mixpanel.track('Operation: Failed to make payment', {
        //             error: 'Unknown Error Occurred',
        //             page: 'Cart',
        //         });
        //         setServerMessage('Unknown Error Occurred');
        //     }

        //     setSuccess(false);
        //     setIsLoadingPopUpShown(false);
        //     setIsPurchaseInfoPopUpShown(true);
        // }
    };

    function hidePopUp() {
        setIsPurchaseInfoPopUpShown(false);
    }

    function clearCartAndLeavePage() {
        Mixpanel.track('Button Tapped: Clear All Items in Cart', {
            page: 'Cart',
        });
        cartRemoveAllItems();
        setIsPurchaseInfoPopUpShown(false);
        window.scrollTo(0, 0);
        history.push('/');
    }

    return (
        <>
            {
                <form onSubmit={handleSubmit}>
                    <fieldset className='FormGroup'>
                        <div className='FormRow'>
                            <CardElement options={CARD_OPTIONS} />
                        </div>
                    </fieldset>
                    <div className='stripe-div'>
                        <p>
                            Payments are securely processed by <i className='fa-brands fa-stripe stripe-logo'></i> Stripe, Inc.
                        </p>
                    </div>
                    <button className='payment-button'>
                        <i className='fa-regular fa-credit-card'></i>Purchase meal plan(s) for £{cartTotal / 100}
                    </button>
                    <br />
                    <br />
                    <br />
                    <LoadingPopUp isLoadingPopUpShown={isLoadingPopUpShown} />
                    <PurchaseInfoPopUp
                        isPurchaseInfoPopUpShown={isPurchaseInfoPopUpShown}
                        serverMessage={serverMessage}
                        success={success}
                        handleOkayButton={hidePopUp}
                        handleSuccessButton={clearCartAndLeavePage}
                    />
                </form>
            }
        </>
    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    cartRemoveAllItems: () => dispatch(cartRemoveAllItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
