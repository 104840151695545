import { useEffect, useState } from 'react';
import AppStatus from "../models/AppStatus";
import { remoteConfig } from '../firebaseConfig';
import { fetchAndActivate, getValue } from 'firebase/remote-config';

const useAppStatus = () => {
    const [appStatus, setAppStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const fetchAppStatus = async () => {
            try {
                await fetchAndActivate(remoteConfig);

                const orderingEnabled = getValue(remoteConfig, 'ordering_enabled').asBoolean();
                const paymentsEnabled = getValue(remoteConfig, 'payments_enabled').asBoolean();
                const registrationEnabled = getValue(remoteConfig, 'registration_enabled').asBoolean();
                const menuVersion = getValue(remoteConfig, 'menu_version').asNumber();

                const mappedAppStatus = new AppStatus({
                    orderingEnabled,
                    paymentsEnabled,
                    registrationEnabled,
                    menuVersion
                });

                setAppStatus(mappedAppStatus);
                localStorage.setItem('appStatus', JSON.stringify(mappedAppStatus));
            } catch (error) {
                console.error('fetchAppStatus:', error);
                setErrorMessage(error.message);
            }
        };

        fetchAppStatus();
    }, []);

    return { appStatus, errorMessage };
};

export default useAppStatus;
