import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel.jsx';
import { auth } from '../../firebaseConfig.js';
import { onAuthStateChanged } from 'firebase/auth';
import ButtonCartCount from '../../components/Cart/ButtonCartCount';
// import PostCodePopUp from '../../components/Home/PostCodePopUp';
import LogInPopUp from "../../components/Home/LogInPopUp";
import MealPlans from '../../components/Home/MealPlans';
import Footer from '../../components/Home/Footer';
import Banner from './Banner';
import AboutUs from './AboutUs';
import useMealPlans from '../../hooks/useMealPlans';
import useMenu from '../../hooks/useMenu';
import useAppStatus from '../../hooks/useAppStatus';
import FloatingWhatsAppButton from '../../components/Home/FloatingWhatsAppButton';

const Home = () => {
    // const [postCodePopUp, setPostCodePopUp] = useState(false);
    const [logInPopUp, setLogInPopUp] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [displayName, setDisplayName] = useState(null);

    const { mealPlans, errorMessage } = useMealPlans();
    const { appStatus } = useAppStatus();
    const { menu } = useMenu();

    const history = useHistory();
    const getStartedRef = useRef();
    const deliveryInfoRef = useRef();
    const bannerRef = useRef();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
                setDisplayName(user.displayName);
            } else {
                setIsLoggedIn(false);
                setDisplayName(null);
            }
        });
    }, []);

    const scrollMealPlansIntoView = () => {
        Mixpanel.track('Button Tapped: View All Meal Plans', {
            page: 'HomePage',
        });
        getStartedRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const scrollDeliveryInfoIntoView = () => {
        Mixpanel.track('Button Tapped: Delivery Info', {
            page: 'HomePage',
        });
        deliveryInfoRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    // const scrollMealPlansIntoViewFromPostCodePopup = () => {
    //     Mixpanel.track('Button Tapped: View our meal plans', {
    //         page: 'PostCodePopUp',
    //     });
    //     setPostCodePopUp(false);
    //     slowSmoothScroll(getStartedRef, 1300, 100);
    // };

    const handleOpenCart = () => {
        Mixpanel.track('Button Tapped: Cart', {
            page: 'HomePage',
        });
        bannerRef.current.scrollIntoView();
        history.push('/cart');
    };

    const TopLeftButtons = () => (
        <div className='floating' style={{ top: 24, left: 16 }}>
            {isLoggedIn ? (
                <div className='horizontally-aligned-div'>
                    <button className='primary-button' onClick={openProfile}>
                        <span>
                            <i className='fa-solid fa-user small-padding-right'></i> Profile
                        </span>
                    </button>
                    <p className='small-title'>Hi, {displayName}!</p>
                </div>
            ) : (
                <div className='horizontally-aligned-div'>
                    {/*<button className='primary-button' onClick={openPostCodePopUp}>*/}
                    {/*    <span>*/}
                    {/*        <i className='fa-solid fa-box-open small-padding-right'></i> Check if we deliver to you*/}
                    {/*    </span>*/}
                    {/*</button>*/}
                    {
                        appStatus == null ?
                            null
                            :
                            <button className='primary-button' onClick={openLogInPopUp}>
                                <span>
                                    <i className='fa-solid fa-arrow-right-to-bracket small-padding-right'></i> Log in
                                </span>
                            </button>
                    }
                </div>
            )}
        </div>
    );

    const MealPlansSection = () => (
        <>
            <div className='centered-text' ref={getStartedRef}>
                {errorMessage ? (
                    <>
                    <h1>Oops!</h1>
                        <p>
                            <i className='fa-solid fa-triangle-exclamation'></i> {errorMessage}
                        </p>
                        <h2>Please try again later.</h2>
                    </>
                ) : (
                    <h1 className='medium-title'>Let&apos;s get started!</h1>
                )}
            </div>
            <MealPlans list={mealPlans} handleOpenMealPlan={handleOpenMealPlan}/>
        </>
    );

    function handleOpenMealPlan(mealPlanItem) {
        Mixpanel.track('Button Tapped: Add Me (on meal plan)', {
            name: mealPlanItem.title,
            page: 'HomePage',
        });
        window.scrollTo(0, 0);
        history.push('/meal-plan', [mealPlanItem, menu, appStatus]);
    }

    function openLogInPopUp() {
        Mixpanel.track('Button Tapped: Log In', {
            page: 'HomePage',
        });
        setLogInPopUp(true);
    }

    function closeLogInPopUp() {
        Mixpanel.track('Button Tapped: Close', {
            page: 'LogInPopUp',
        });
        setLogInPopUp(false);
    }

    // function openPostCodePopUp() {
    //     Mixpanel.track('Button Tapped: Check if we deliver to you', {
    //         page: 'HomePage',
    //     });
    //     setPostCodePopUp(true);
    // }

    function openProfile() {
        Mixpanel.track('Button Tapped: Profile', {
            page: 'HomePage',
        });
        history.push('/profile');
    }

    return (
        <div>
            <Banner
                handleScrollMealPlans={scrollMealPlansIntoView}
                handleScrollDeliveryInfo={scrollDeliveryInfoIntoView}
                ref={bannerRef}
            />
            <AboutUs
                deliveryInfoRef={deliveryInfoRef}
            />
            <MealPlansSection/>
            <Footer/>
            <TopLeftButtons/>
            <ButtonCartCount handleOpenCart={handleOpenCart}/>
            {/*<PostCodePopUp*/}
            {/*    closePostCodePopUp={() => setPostCodePopUp(false)}*/}
            {/*    viewMealPlans={scrollMealPlansIntoViewFromPostCodePopup}*/}
            {/*    postCodePopUp={postCodePopUp}*/}
            {/*/>*/}
            <LogInPopUp
                closeLogInPopUp={closeLogInPopUp}
                openRegistration={() => history.push('/register')}
                logInPopUp={logInPopUp}
                appStatus={appStatus}
            />
            <FloatingWhatsAppButton/>
        </div>
    );
};

export default Home;
