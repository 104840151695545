/**
 * @typedef {Object} AppStatus
 * @property {boolean} orderingEnabled
 * @property {boolean} paymentsEnabled
 * @property {boolean} registrationEnabled
 * @property {number} menuVersion
 */

class AppStatus {
    /**
     * @param {AppStatus} data
     */
    constructor(data) {
        this.orderingEnabled = data.orderingEnabled || false
        this.paymentsEnabled = data.paymentsEnabled || false;
        this.registrationEnabled = data.registrationEnabled || false;
        this.menuVersion = data.menuVersion || 0;
    }
}

export default AppStatus;
