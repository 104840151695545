/**
 * @typedef {Object} MealPlan
 * @property {string} id
 * @property {string} name
 * @property {number} minCalories
 * @property {number} maxCalories
 * @property {number} order
 * @property {string} photoURL
 * @property {number} priceInCents
 */

class MealPlan {
    /**
     * @param {MealPlan} data
     */
    constructor(data) {
        this.id = data.id;
        this.name = data.name || 'Untitled';
        this.minCalories = data.minCalories || 0;
        this.maxCalories = data.maxCalories || 0;
        this.order = data.order || 0;
        this.photoURL = this.extractPhotoURL(data.photo);
        this.priceInCents = data.priceInCents || 0;
    }

    /**
     * Extracts the photo URL from the first photo object in the array.
     * @param {Object[]} photoArray
     * @returns {string}
     */
    extractPhotoURL(photoArray) {
        if (Array.isArray(photoArray) && photoArray.length > 0) {
            return photoArray[0].downloadURL || '';
        }
        return '';
    }
}

export default MealPlan;
