import { useEffect, useState } from 'react';
import Meal from "../models/Meal";
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';

const CACHE_KEY_MENU = 'cachedMenu';
const CACHE_KEY_MENU_VERSION = 'cachedMenuVersion';
const CACHE_KEY_MENU_TIMESTAMP = 'cachedMenuTimestamp';
const CACHE_EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000; // 1 week

const useMenu = () => {
    const [menu, setMenu] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const fetchMenu = async () => {
            try {
                const cachedVersion = localStorage.getItem(CACHE_KEY_MENU_VERSION);
                const cachedTimestamp = localStorage.getItem(CACHE_KEY_MENU_TIMESTAMP);
                const cachedMenu = localStorage.getItem(CACHE_KEY_MENU);

                // Retrieve menuVersion from AppStatus
                const appStatus = JSON.parse(localStorage.getItem('appStatus'));
                const currentVersion = appStatus?.menuVersion;

                const isCacheValid =
                    cachedVersion === String(currentVersion) &&
                    cachedTimestamp &&
                    Date.now() - parseInt(cachedTimestamp) < CACHE_EXPIRATION_TIME;

                if (isCacheValid && cachedMenu) {
                    console.log('Using cached menu');
                    setMenu(JSON.parse(cachedMenu));
                } else {
                    console.log('Fetching menu from server');
                    const menuFunction = httpsCallable(functions, 'menu');
                    const response = await menuFunction();
                    const mappedMenu = response.data.map((item) => new Meal(item));

                    setMenu(mappedMenu);

                    // Cache the menu
                    localStorage.setItem(CACHE_KEY_MENU, JSON.stringify(mappedMenu));
                    localStorage.setItem(CACHE_KEY_MENU_VERSION, currentVersion);
                    localStorage.setItem(CACHE_KEY_MENU_TIMESTAMP, Date.now().toString());
                }
            } catch (error) {
                console.error('fetchMenu:', error);
                setErrorMessage(error.message || 'Failed to fetch menu');
            }
        };

        fetchMenu();
    }, []);

    return { menu, errorMessage };
};

export default useMenu;
