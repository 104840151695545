import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { updateCustomizedMenuItem } from '../../../redux/mealplan/mealplan.action';
import './styles.css';
import Meal from '../../../models/Meal';

const MenuItemPopUpDetails = ({ closeItemDetails, mealPlanId, item, replacementItem, fullMenu }) => {
    const [replacementMeal, setReplacementMeal] = useState(null);

    useEffect(() => {
        if (replacementItem) {
            setReplacementMeal(replacementItem);
        }
    }, [replacementItem]);

    const handleChange = (event) => {
        const newMenuItem = fullMenu.find(
            (menuItem) => menuItem.id === event.target.value
        );
        if (newMenuItem) {
            // updateCustomizedMenuItem(mealPlanId, item.family, newMenuItem);

            const newMeal = new Meal(newMenuItem);
            setReplacementMeal(newMeal);
        }
    };

    const OptionList = () => {
        if (item.replacements.length > 0) {
            return (
                <div className='option-list-div-details'>
                    <select value={replacementMeal?.id || ''} onChange={handleChange}>
                        {item.replacements.map((replacement, index) => (
                            <option key={index} value={replacement.id}>
                                {replacement.name}
                            </option>
                        ))}
                    </select>
                    <p>
                        <i className='fa-solid fa-shuffle'></i>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    function getItemPhotoURL() {
        return replacementMeal?.photoURL || item.photoURL
    }

    function getItemName() {
        return replacementMeal?.name || item.name;
    }

    function getItemDescription() {
        return replacementMeal?.description || item.description;
    }

    function getItemCalories() {
        const replacementMealCalories = replacementMeal?.findConfigurationByMealPlanId(mealPlanId).calories
        const itemCalories = item.findConfigurationByMealPlanId(mealPlanId).calories
        return replacementMealCalories || itemCalories || '-'
    }

    function close() {
        closeItemDetails(item, replacementMeal);
        setReplacementMeal(null);
    }

    return item ? (
        <div className='modal'>
            <div className='modal-content'>
                <button className='menu-item-close-button' onClick={close}>
                    <i className='fa-solid fa-xmark'></i> Close
                </button>
                <img
                    className='menu-pop-up-img'
                    src={getItemPhotoURL()}
                    alt='meal-img'
                ></img>
                <div className='meal-content-div'>
                    <h1 className='meal-name'>{getItemName()}</h1>
                    <p className='meal-info'>{getItemDescription()}</p>
                    <OptionList />
                    <div className='pop-up-item-calories-div'>
                        <p className='pop-up-item-calories'>
                            <b>{getItemCalories()}</b> Calories
                        </p>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

// const mapDispatchToProps = (dispatch) => ({
//     updateCustomizedMenuItem: (mealPlanId, mealType, mealFamily, updatedItem) =>
//         dispatch(updateCustomizedMenuItem(mealPlanId, mealType, mealFamily, updatedItem)),
// });
//
// export default connect(null, mapDispatchToProps)(MenuItemPopUpDetails);

export default MenuItemPopUpDetails;