import React, { forwardRef } from 'react';
import './banner-style.css';

const Banner = forwardRef(({ handleScrollMealPlans, handleScrollDeliveryInfo }, ref) => (
    <main ref={ref}>
        <header>
            <div>
                <p className='large-title' style={{ animation: 'comeFromTop 0.4s' }}>Mokka Food</p>
                <p className='subtitle' style={{ animation: 'comeFromBottom 0.8s' }}>
                    Freshly prepared 5-day weekly meals <br/> for anywhere in the UK 🤩
                </p>
                <img className='tablet-only' style={{ animation: 'comeFromTop 0.8s' }} src='/images/banner.jpg'
                     alt='mobile-banner'/>
                <button className='primary-button' style={{ width: 240, animation: 'comeFromBottom 1s' }}
                        onClick={handleScrollMealPlans}>
                    View Meal Plans <i className='fa-solid fa-chevron-down small-padding-left'></i>
                </button>
                <button className='primary-button' style={{ width: 240, animation: 'comeFromBottom 1.2s' }}
                        onClick={handleScrollDeliveryInfo}>
                    Delivery Info <i className='fa-solid fa-chevron-down small-padding-left'></i>
                </button>
            </div>
            <img className='desktop-only' style={{ animation: 'comeFromLeft 0.8s' }} src='/images/banner.jpg'
                 alt='desktop-banner'/>
        </header>
    </main>
));

export default Banner;
