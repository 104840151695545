import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getRemoteConfig } from 'firebase/remote-config';
import { getAuth } from 'firebase/auth';

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_V3_PROVIDER_KEY;
const firebaseConfigApiKey = process.env.REACT_APP_FIREBASE_CONFIG_API_KEY;
const firebaseConfigAppId = process.env.REACT_APP_FIREBASE_CONFIG_APP_ID;
const firebaseConfigAuthDomain = process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN;
const firebaseConfigDatabaseUrl = process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL;
const firebaseConfigMeasurementId = process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID;
const firebaseConfigMessagingSenderId = process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID;
const firebaseConfigProjectId = process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID;
const firebaseConfigStorageBucket = process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET;
const firebaseConfigStorageBucketInitUrl = process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET_INIT_URL;

if (!recaptchaKey) {
  console.error('recaptchaKey is missing!');
}
if (!firebaseConfigApiKey) {
  console.error('firebaseConfigApiKey is missing!');
}
if (!firebaseConfigAppId) {
  console.error('firebaseConfigAppId is missing!');
}
if (!firebaseConfigAuthDomain) {
  console.error('firebaseConfigAuthDomain is missing!');
}
if (!firebaseConfigDatabaseUrl) {
  console.error('firebaseConfigDatabaseUrl is missing!');
}
if (!firebaseConfigMeasurementId) {
  console.error('firebaseConfigMeasurementId is missing!');
}
if (!firebaseConfigMessagingSenderId) {
  console.error('firebaseConfigMessagingSenderId is missing!');
}
if (!firebaseConfigProjectId) {
  console.error('firebaseConfigProjectId is missing!');
}
if (!firebaseConfigStorageBucket) {
  console.error('firebaseConfigStorageBucket is missing!');
}
if (!firebaseConfigStorageBucketInitUrl) {
  console.error('firebaseConfigStorageBucketInitUrl is missing!');
}

const firebaseConfig = {
  apiKey: firebaseConfigApiKey,
  authDomain: firebaseConfigAuthDomain,
  databaseURL: firebaseConfigDatabaseUrl,
  projectId: firebaseConfigProjectId,
  storageBucket: firebaseConfigStorageBucket,
  messagingSenderId: firebaseConfigMessagingSenderId,
  appId: firebaseConfigAppId,
  measurementId: firebaseConfigMeasurementId
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const database = getDatabase(firebaseApp);
export const functions = getFunctions(firebaseApp, 'europe-west2');
export const storage = getStorage(firebaseApp, firebaseConfigStorageBucketInitUrl);
export const remoteConfig = getRemoteConfig(firebaseApp);
export const auth = getAuth(firebaseApp);

remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000, // Fetching once per hour
};
remoteConfig.defaultConfig = {
  ordering_enabled: false,
  payments_enabled: false,
  registration_enabled: false,
};

if (location.hostname === "localhost") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  connectFunctionsEmulator(functions, 'localhost', 5001);
} else {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(recaptchaKey),
    isTokenAutoRefreshEnabled: true
  });
}