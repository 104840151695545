import React from 'react';
import './styles.css';

/**
 * MealPlanItem component displays a meal plan card.
 *
 * @param {Object} props
 * @param {MealPlan} props.item - The meal plan item to display.
 * @param {Function} props.handleOpenMealPlan - Function to handle clicking the "Open Me" button.
 */
const MealPlanItem = ({ item, handleOpenMealPlan }) => {
    const { id, name, minCalories, maxCalories, photoURL, priceInCents } = item;

    // Render calorie info based on whether min and max are the same or different
    const Info = () => (
        <p className='meal-plan-info'>
            <span>
                {minCalories === maxCalories
                    ? `${minCalories} Calories`
                    : `${minCalories} - ${maxCalories} Calories`}
            </span>
        </p>
    );

    return (
        <div className='item-meal-plan'>
            <img className='item-image' src={photoURL} alt={name} />
            <div className='item-head_desc'>
                <p className='meal-plan-name'>{name}</p>
                <Info />
            </div>
            <div className='item-add-button-div'>
                <p className='item-meal-plan-price'>£{(priceInCents / 100).toFixed(2)}</p>
                <button
                    className='meal-plan-button'
                    onClick={() => handleOpenMealPlan(id)}
                >
                    Open Me <i className='fa-solid fa-utensils'></i>
                </button>
            </div>
        </div>
    );
};

export default MealPlanItem;
