import React, { forwardRef } from 'react';
import MealPlanItem from './MealPlanItem';
import './styles.css';

const Menu = forwardRef(({ list, handleOpenMealPlan }, ref) => (
    <main ref={ref}>
        <div className='meal-plans-desc'>
            <p>The price is for a 5-day meal plan with 3 meals per day - breakfast, lunch and dinner.</p>
            <br/>
            <p><b>Delivery is FREE! 🚚</b></p>
        </div>
        <div className='meal-plans-div'>
            {list.map((item) => (
                <MealPlanItem item={item} handleOpenMealPlan={() => handleOpenMealPlan(item)} key={item.id} />
            ))}
        </div>
    </main>
));

export default Menu;
