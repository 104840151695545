/**
 * @typedef {Object} Ingredient
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} MealConfiguration
 * @property {string} mealPlanId
 * @property {number} calories
 * @property {number} protein
 * @property {number} carbohydrates
 * @property {number} fat
 */

/**
 * @typedef {Object} MealReplacement
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} Meal
 * @property {string} id
 * @property {string} name
 * @property {string} description
 * @property {string} photoURL
 * @property {string} day
 * @property {string} mealTime
 * @property {string[]} ingredients
 * @property {MealConfiguration[]} configurations
 * @property {MealReplacement[]} replacements
 */

class Meal {
    /**
     * @param {Meal | Object} data - A Meal instance or a raw data object to initialize the Meal.
     */
    constructor(data) {
        this.id = data.id;
        this.name = data.name || '-';
        this.description = data.description || '-';
        this.photoURL = data.photoURL || this.extractPhotoURL(data.photo);
        this.day = data.day || '-';
        this.mealTime = data.mealTime || '-';
        this.ingredients = this.mapIngredients(data.ingredients); // Map ingredients to string[]
        this.configurations = data.configurations || this.mapConfigurations(data.plans); // Map plans to MealConfiguration[]
        this.replacements = data.replacements || this.mapReplacements(data.replaceableBy); // Map plans to MealReplacement[]
    }

    /**
     * Extracts the photo URL from the first photo object in the array.
     * @param {Object[]} photoArray
     * @returns {string}
     */
    extractPhotoURL(photoArray) {
        if (Array.isArray(photoArray) && photoArray.length > 0) {
            return photoArray[0].downloadURL || '';
        }
        return '';
    }

    /**
     * Maps ingredients to an array of their names.
     * @param {Ingredient[]} ingredients
     * @returns {string[]}
     */
    mapIngredients(ingredients) {
        if (Array.isArray(ingredients) && ingredients.every(item => typeof item === 'string')) {
            return ingredients;
        }
        if (Array.isArray(ingredients)) {
            return ingredients.map(ingredient => ingredient.name);
        }
        return [];
    }

    /**
     * Maps plans to an array of MealConfiguration instances.
     * @param {MealConfiguration[]} plans
     * @returns {MealConfiguration[]}
     */
    mapConfigurations(plans) {
        if (Array.isArray(plans)) {
            return plans.map(plan => new MealConfiguration(plan));
        }
        return [];
    }

    /**
     * Maps plans to an array of MealReplacement instances.
     * If replacements exist and are non-empty, the meal itself is added as the first replacement.
     * @param {MealReplacement[]} replacements
     * @returns {MealReplacement[]}
     */
    mapReplacements(replacements) {
        const mappedReplacements = Array.isArray(replacements)
            ? replacements.map(replacement => new MealReplacement(replacement))
            : [];

        if (mappedReplacements.length > 0) {
            mappedReplacements.unshift(new MealReplacement({ id: this.id, name: this.name }));
        }

        return mappedReplacements;
    }

    /**
     * Finds the first configuration matching a given mealPlanId.
     * @param {string} mealPlanId
     * @returns {MealConfiguration | undefined}
     */
    findConfigurationByMealPlanId(mealPlanId) {
        const configuration = this.configurations.find(
            config => config.mealPlanId === mealPlanId
        );
        return configuration || new MealConfiguration({ mealPlanId: "-" });
    }
}

class MealConfiguration {
    /**
     * @param {MealConfiguration} data
     */
    constructor(data) {
        this.mealPlanId = data.mealPlanId;
        this.calories = data.calories || 0;
        this.protein = data.protein || 0;
        this.carbohydrates = data.carbohydrates || 0;
        this.fat = data.fat || 0;
    }
}

class MealReplacement {
    /**
     * @param {MealReplacement} data
     */
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
    }
}

export default Meal;
