import React, { useState, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { functions, auth } from '../../../firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { updateProfile } from 'firebase/auth';
import { Mixpanel } from "../../../components/Mixpanel/Mixpanel";
import { checkPostCode } from "../../../components/PaymentForm/postCodeCheck";
import LoadingPopUp from "../../../components/common/LoadingPopUp";

const EditProfile = forwardRef((props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [formData, setFormData] = useState({
        firstName: props.location.state[0].firstName,
        lastName: props.location.state[0].lastName,
        email: props.location.state[0].email,
        phone: props.location.state[0].phone,
        address: props.location.state[0].address,
        city: 'London',
        postCode: props.location.state[0].postCode,
        marketingAccepted: props.location.state[0].marketingAccepted,
    });

    const history = useHistory();

    const handleInputChange = (e) => {
        const { id, value, type, checked } = e.target;

        setErrorMessage(null);
        setFormData({
            ...formData,
            [id]: type === 'checkbox' ? checked : value
        });
    };

    function isPhoneNumberCorrect(phoneNumber) {
        const trimmedPhoneNumber = phoneNumber.trim().replaceAll(' ','');
        if (trimmedPhoneNumber.charAt(0) === '0') {
            return trimmedPhoneNumber.length >= 10 && trimmedPhoneNumber.length <= 12;
        } else if (trimmedPhoneNumber.charAt(0) === '+' && trimmedPhoneNumber.charAt(1) === '4' && trimmedPhoneNumber.charAt(2) === '4') {
            return trimmedPhoneNumber.length >= 12 && trimmedPhoneNumber.length <= 14;
        } else {
            return false;
        }
    }

    async function isFormCompleted() {
        if (formData.firstName === '') {
            setErrorMessage('Empty Field: First name');
            return false;
        }

        if (formData.lastName === '') {
            setErrorMessage('Empty Field: Last name');
            return false;
        }

        if (formData.phone === '') {
            setErrorMessage('Empty Field: Phone number');
            return false;
        }

        if (isPhoneNumberCorrect(formData.phone) === false) {
            setErrorMessage('Invalid phone number. Could you please check to make sure it is correct?');
            return false;
        }

        if (formData.address === '') {
            setErrorMessage('Empty Field: Delivery address');
            return false;
        }

        if (formData.postCode === '') {
            setErrorMessage('Empty Field: Post code');
            return false;
        }

        if (formData.postCode.length >= 5) {
            const postCodeToCheck = formData.postCode;
            const { postCodeError, isPostCodeCensored, isPostCodeSupported } = await checkPostCode(postCodeToCheck);
            if (isPostCodeCensored) {
                setErrorMessage(`We couldn't find this post code. Could you please check that it is correct?`);
                return false;
            }
            if (postCodeError) {
                setErrorMessage(postCodeError);
                return false;
            }
            if (isPostCodeSupported) {
                return true;
            }
        } else {
            setErrorMessage(`We couldn't find this post code. Could you please check that it is correct?`);
            return false;
        }
    }

    const handleSubmit = async () => {
        if (errorMessage) {
            return;
        }

        const isReadyToProceed = await isFormCompleted();

        if (isReadyToProceed === false) {
            return;
        }

        setIsLoading(true);

        const oldUser = props.location.state[0]

        const updatedUser = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: oldUser.email,
            phone: formData.phone.trim().replaceAll(' ',''),
            address: formData.address,
            postCode: formData.postCode.toUpperCase(),
            city: oldUser.city,
            termsAccepted: oldUser.termsAccepted,
            marketingAccepted: formData.marketingAccepted,
        };

        try {
            const updateUser = httpsCallable(functions, 'updateUser');
            updateUser({ userId: oldUser.id, updatedUser: updatedUser }).then(() => {

                updateProfile(auth.currentUser, {
                    displayName: `${updatedUser.firstName}`,
                    phoneNumber: updatedUser.phone,
                }).then(() => {

                    setIsLoading(false);
                    history.goBack();

                }).catch((error) => {
                    setIsLoading(false);
                    setErrorMessage(error.message);
                });

            }).catch((error) => {
                const errorMessage = error?.message ?? 'Unknown Error Occurred';

                Mixpanel.track('Operation: Failed to edit profile', {
                    error: errorMessage,
                    page: 'Edit my profile',
                });

                setIsLoading(false);
                setErrorMessage(errorMessage);
            });
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className='registration-container'>
            <div className='horizontally-aligned-div large-margin-top' style={{ animation: 'comeFromTop 0.4s' }}>
                <button className='primary-button' onClick={() => history.goBack()}>
                    <i className='fa fa-arrow-left small-padding-right'></i>Back
                </button>
                <p className='medium-title'>Edit my profile</p>
            </div>
            <form className='standard-form large-margin-top' style={{ animation: 'comeFromLeft 0.8s' }}>
                <p className='footnote'>We need this information in order to deliver your orders to you.</p>
                <div className='horizontally-aligned-div-no-gap-on-mobile'>
                    <input
                        type='text'
                        id='firstName'
                        placeholder='First name'
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='text'
                        id='lastName'
                        placeholder='Last name'
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='tel'
                        id='phone'
                        placeholder='Phone number'
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className='horizontally-aligned-div-no-gap-on-mobile standard-margin-bottom'>
                    <input
                        type='text'
                        id='address'
                        placeholder='Delivery address'
                        value={formData.address}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='text'
                        id='postCode'
                        placeholder='Post code'
                        value={formData.postCode}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='text'
                        id='city'
                        placeholder='City'
                        value={formData.city}
                        readOnly
                    />
                </div>
                <div className='always-horizontally-aligned-div standard-margin-bottom'
                     style={{ gap: '12px', marginLeft: '12px' }}>
                    <input
                        type='checkbox'
                        id='marketingAccepted'
                        checked={formData.marketingAccepted}
                        onChange={handleInputChange}
                        required
                    />
                    <p className='footnote'>
                        I consent to receiving marketing emails and promotional offers.
                    </p>
                </div>
            </form>
            <button className='primary-button standard-margin-top'
                    style={{ animation: 'comeFromLeft 1s' }}
                    onClick={handleSubmit}>
                <i className='fa fa-pen-to-square small-padding-right'></i> Confirm changes
            </button>
            {
                errorMessage === null ? null :
                    <div className='error-message-div'>
                        <p><i className="fa-solid fa-triangle-exclamation"></i> {errorMessage}</p>
                    </div>
            }
            <LoadingPopUp isLoadingPopUpShown={isLoading}/>
        </div>
    );
});

export default EditProfile;
