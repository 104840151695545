import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { extraItemsData } from '../../data';
import { cartAddItem, cartRemoveItem } from '../../../../redux/cart/cart.action';
import { selectCartItems, selectCartItemsCount } from '../../../../redux/cart/cart.selector';
import MenuItemPopUpDetails from '../../MenuItemPopUpDetails';
import MenuXtra from '../MenuXtra';
import Footer from '../../../Home/Footer';
import './styles.css';

const ExtraMenu = () => {
    const [itemDetailsPopUp, setItemDetailsPopUp] = useState(null);
    const history = useHistory();

    function openItemDetails() {
    // const item = menuItemsData.find((item) => item.id === id)
    // setItemDetailsPopUp(item)
    }

    function closeItemDetails() {
        setItemDetailsPopUp(null);
    }

    //   function addMealPlanToCart() {
    //     var mealPlanForCart = {
    //       ...getMealPlan()
    //     }

    //     mealPlanForCart.id = crypto.randomUUID()
    //     props.cartAddItem(mealPlanForCart)

    //     for (let i = 0; i < menuItemsData.length; i++) {
    //       var mealItemForCart = {
    //         ...menuItemsData[i]
    //       }

    //       mealItemForCart.id = crypto.randomUUID()
    //       props.cartAddItem(mealItemForCart)

    //       resetMenuItemCustomization(menuItemsData[i])
    //     }

    //     setThankYouPopUp(getMealPlan())
    //   }

    //   function resetMenuItemCustomization(menuItem) {
    //     delete menuItem.selectedOption;
    //     delete menuItem.selectedImage;

    //     if (menuItem.calories != null && menuItem.options != null) {
    //       if (menuItem.options.calories[0] != null) {
    //         menuItem.calories = menuItem.options.calories[0]
    //       }
    //     }
    //   }

    return (
        <main>
            <div className='top-buttons-div'>
                <button className='back-button' onClick={() => history.push('/')}>
                    <i className="fa fa-arrow-left"></i>Go Back
                </button>
            </div>
            <MenuXtra openItemDetails={openItemDetails} list={extraItemsData}/>
            <MenuItemPopUpDetails closeItemDetails={closeItemDetails} item={itemDetailsPopUp} />
            <Footer />
        </main>
    );
};

const mapStateToProps = createStructuredSelector({
    cartCount: selectCartItemsCount,
    cartList: selectCartItems,
});

const mapDispatchToProps = (dispatch) => ({
    cartAddItem: (item) => dispatch(cartAddItem(item)),
    cartRemoveItem: (item) => dispatch(cartRemoveItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtraMenu);
