import { useEffect, useState } from 'react';
import MealPlan from "../models/MealPlan";
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';

const CACHE_KEY_MEAL_PLANS = 'cachedMealPlans';
const CACHE_KEY_MEAL_PLANS_VERSION = 'cachedMealPlansVersion';
const CACHE_KEY_MEAL_PLANS_TIMESTAMP = 'cachedMealPlansTimestamp';
const CACHE_EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000; // 1 week

const useMealPlans = () => {
    const [mealPlans, setMealPlans] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const fetchMealPlans = async () => {
            try {
                const cachedVersion = localStorage.getItem(CACHE_KEY_MEAL_PLANS_VERSION);
                const cachedTimestamp = localStorage.getItem(CACHE_KEY_MEAL_PLANS_TIMESTAMP);
                const cachedMealPlans = localStorage.getItem(CACHE_KEY_MEAL_PLANS);

                // Retrieve menuVersion from AppStatus
                const appStatus = JSON.parse(localStorage.getItem('appStatus'));
                const currentVersion = appStatus?.menuVersion;

                const isCacheValid =
                    cachedVersion === String(currentVersion) &&
                    cachedTimestamp &&
                    Date.now() - parseInt(cachedTimestamp) < CACHE_EXPIRATION_TIME;

                if (isCacheValid && cachedMealPlans) {
                    console.log('Using cached meal plans');
                    setMealPlans(JSON.parse(cachedMealPlans));
                } else {
                    console.log('Fetching meal plans from server');
                    const mealPlanFunction = httpsCallable(functions, 'mealPlans');
                    const response = await mealPlanFunction();
                    const mappedMealPlans = response.data
                        .map((item) => new MealPlan(item))
                        .sort((a, b) => a.order - b.order);

                    setMealPlans(mappedMealPlans);

                    // Cache the meal plans
                    localStorage.setItem(CACHE_KEY_MEAL_PLANS, JSON.stringify(mappedMealPlans));
                    localStorage.setItem(CACHE_KEY_MEAL_PLANS_VERSION, currentVersion);
                    localStorage.setItem(CACHE_KEY_MEAL_PLANS_TIMESTAMP, Date.now().toString());
                }
            } catch (error) {
                console.error('fetchMealPlans:', error);
                setErrorMessage(error.message || 'Failed to fetch meal plans');
            }
        };

        fetchMealPlans();
    }, []);

    return { mealPlans, errorMessage };
};

export default useMealPlans;
