import React from 'react';
import './FloatingWhatsAppButton.css';

const FloatingWhatsAppButton = () => {
    const phone = "+447729071647";
    const message = "Hello! I clicked on the WhatsApp icon in mokkafood.co.uk! I have some questions about your meal plans.";
    const whatsappURL = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;

    return (
        <div className='image-container' style={{ position: 'fixed', bottom: '16px', right: '16px' }}>
            {/*<p>Connect on WhatsApp!</p>*/}
            <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
                <img
                    src="/images/whatsapp-icon.png"
                    alt="WhatsApp"
                    className='animated-glow'
                />
            </a>
        </div>
    );
};

export default FloatingWhatsAppButton;
