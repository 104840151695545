import { Mixpanel } from "../Mixpanel/Mixpanel";

const censoredWords = [
    "abuse", "ass", "asshole", "bastard", "bitch", "bollocks", "bullshit",
    "crap", "cunt", "damn", "dick", "dickhead", "douche", "dumbass", "fag",
    "faggot", "fuck", "fucker", "fucking", "goddamn", "hell", "homo", "jerk",
    "motherfucker", "nigga", "nigger", "piss", "prick", "pussy", "shit", "shitty",
    "slut", "son of a bitch", "twat", "whore", "wanker", "arse", "arsehole",
    "bint", "bitchass", "bloody", "bugger", "choad", "clunge", "cock", "cox",
    "dickish", "dildo", "dyke", "feck", "frigger", "jizz", "knob", "knobhead",
    "minger", "minging", "muff", "munter", "nobjock", "nonce", "pillock", "plonker",
    "poof", "prat", "scrubber", "scrote", "shitbag", "shithead", "skank", "slag",
    "spacker", "spaz", "sperm", "spunk", "swine", "tit", "tosser", "turd", "twit",
    "wazzock", "willy", "arsewipe", "bollock", "bullshitter", "cockface", "cockhead",
    "cockmuncher", "cocksucker", "crikey", "dagnabbit", "dang", "flipping", "goddam",
    "jerkoff", "knobend"
];

function containsCensoredWord(inputString) {
    return censoredWords.some(word => inputString.toLowerCase().includes(word));
}

function calculateDistance(lat1, lon1, lat2, lon2) {
    // const R = 3958.8; // Radius of the Earth in miles
    const radLat1 = (Math.PI * lat1) / 180;
    const radLat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radTheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return dist;
}

export async function checkPostCode(postCode) {
    const postCodeToCheck = postCode;

    if (!postCodeToCheck) {
        return {
            postCodeError: 'The post code field is empty. Please enter a post code.',
            isPostCodeSupported: false,
        };
    }

    if (containsCensoredWord(postCodeToCheck)) {
        return {
            postCodeError: `Well, that's not very nice! Please enter a valid post code.`,
            isPostCodeCensored: true,
            isPostCodeSupported: false,
        };
    }

    if (postCodeToCheck.length < 5 || postCodeToCheck.length > 8) {
        return {
            postCodeError: `Invalid post code ${postCodeToCheck.toUpperCase()}. Please enter a valid post code.`,
            isPostCodeSupported: false,
        };
    }

    const cleanPostCode = postCodeToCheck.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');

    try {
        const response = await fetch(`https://api.postcodes.io/postcodes/${cleanPostCode}`);

        if (!response.ok) {
            throw new Error('Postcode lookup failed');
        }

        const data = await response.json();

        if (data.status === 200 && data.result) {
            // Coordinates of E4 9EP
            const baseLatitude = 51.617997;
            const baseLongitude = -0.013842;

            const distanceInMiles = calculateDistance(baseLatitude, baseLongitude, data.result.latitude, data.result.longitude);

            if (distanceInMiles <= 5) {
                Mixpanel.track('Operation: Post code is supported! [successful operation]', {
                    userInput: postCodeToCheck,
                    page: 'Cart',
                });
                return {
                    postCodeError: null,
                    isPostCodeSupported: true,
                };
            } else {
                Mixpanel.track('Operation: Post code is in unsupported delivery region', {
                    userInput: postCodeToCheck,
                    page: 'Cart',
                });
                return {
                    postCodeError: `Unfortunately, we don't deliver to post code ${postCodeToCheck.toUpperCase()} yet. You could send us an e-mail to request delivery for your location at orders@mokkafood.co.uk!`,
                    isPostCodeSupported: false,
                };
            }
        } else {
            return {
                postCodeError: `Invalid post code ${postCodeToCheck.toUpperCase()}. Please enter a valid post code.`,
                isPostCodeSupported: false,
            };
        }
    } catch (error) {
        console.error(error);
        Mixpanel.track('Operation: api.postcodes.io failed to find post code', {
            userInput: postCodeToCheck,
            postcodesioError: error,
            page: 'Cart',
        });
        return {
            postCodeError: `Invalid post code ${postCodeToCheck.toUpperCase()}. Please enter a valid post code.`,
            isPostCodeSupported: false,
        };
    }
}