import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { cartRemoveAllItems } from '../../redux/cart/cart.action';
import { selectCartItems, selectCartItemsCount, selectCartTotal } from '../../redux/cart/cart.selector';
import AreYouSurePopUp from "../../components/common/AreYouSurePopUp";
import CartMenu from '../../components/Cart/CartMenu/CartMenu';
import EmptyCart from '../../components/Cart/EmptyCart';
import Footer from '../../components/Home/Footer';
import Payment from './Payment';
import './styles.css';

const Cart = ({ cartCount, cartList, cartTotal, cartRemoveAllItems }) => {
    const [areYouSurePopUp, setAreYouSurePopUp] = useState(false);
    const history = useHistory();

    const CartListDescription = () => {
        if (!cartList) return null;

        return cartList.map((plan) => (
            <div key={plan.id}>
                <h3 className='orders-total-description-plan'>
                    {plan.title}
                    <span className='order-description-item-price'>£{plan.price / 100}</span>
                    <span className='order-description-item-price'>
                        <i className='fa-solid fa-arrow-turn-down'></i>
                    </span>
                </h3>
                {renderItemsByDay(plan)}
            </div>
        ));
    };

    const renderItemsByDay = (plan) => {
        const itemsByDay = {};

        const addItem = (item, type, dayIndex) => {
            const day = dayOfWeek(dayIndex);
            if (!itemsByDay[day]) itemsByDay[day] = { Breakfast: [], Lunch: [], Dinner: [] };
            itemsByDay[day][type].push(item);
        };

        ['Breakfast', 'Lunch', 'Dinner'].forEach((mealType) => {
            plan[mealType].forEach((item, index) => addItem(item, mealType, index));
        });

        return Object.entries(itemsByDay).map(([day, meals]) => (
            <div className='orders-total-description-item-div' key={day}>
                <p className='orders-total-description-item-day'>{day}</p>
                {['Breakfast', 'Lunch', 'Dinner'].map((mealType) =>
                    meals[mealType].map((mealItem, index) => (
                        <div key={`${mealType}-${index}`}>
                            <h3 className='orders-total-description-meal-type'>{mealType}</h3>
                            <h3 className='orders-total-description-item'>
                                {mealItem.title}{' '}
                                <span className='orders-total-description-item-option'>
                                    {mealItem.selectedOption ? `with ${mealItem.selectedOption.title} ` : ''}
                                    {mealItem.selectedOption ? <i className='fa-regular fa-star'></i> : null}
                                </span>
                            </h3>
                        </div>
                    ))
                )}
            </div>
        ));
    };

    function dayOfWeek(dayOfWeekIndex) {
        switch (dayOfWeekIndex) {
            case 0:
                return 'Monday';
            case 1:
                return 'Tuesday';
            case 2:
                return 'Wednesday';
            case 3:
                return 'Thursday';
            case 4:
                return 'Friday';
            case 5:
                return 'Saturday';
            case 6:
                return 'Sunday';
            default:
                return null;
        }
    }

    function calculateDeliveryDate(addDay) {
        const date = new Date();

        if (date.getDay() <= 5) {
            date.setDate(date.getDate() + (7 - date.getDay() + addDay));
            return date.toDateString();
        } else {
            date.setDate(date.getDate() + (7 - date.getDay() + addDay) + 7);
            return date.toDateString();
        }
    }

    function calculateSecondDeliveryDate(addDay) {
        const date = new Date();

        if (date.getDay() <= 5) {
            date.setDate(date.getDate() + (7 - date.getDay() + addDay + 2));
            return date.toDateString();
        } else {
            date.setDate(date.getDate() + (7 - date.getDay() + addDay) + 7 + 2);
            return date.toDateString();
        }
    }

    function getDeliveryDateForBE() {
        return calculateDeliveryDate(0) + ' (evening) or ' + calculateDeliveryDate(1) + ' (morning)';
    }

    function getSecondDeliveryDateForBE() {
        return calculateSecondDeliveryDate(0) + ' (evening) or ' + calculateSecondDeliveryDate(1) + ' (morning)';
    }

    return (
        <>
            <div className='cart-header'>
                <button className='back-button' onClick={() => history.push('/')}>
                    <i className='fa fa-arrow-left'></i>Go Back
                </button>
                {cartCount !== 0 ? (
                    <button
                        className='clear-cart-button'
                        onClick={() => {
                            setAreYouSurePopUp(true);
                        }}
                    >
                        <i className='fa fa-trash'></i>Clear Cart
                    </button>
                ) : null}
            </div>
            {cartCount === 0 ? (
                <EmptyCart />
            ) : (
                <div className='orders'>
                    <h1 className='orders-heading'>Order Information</h1>
                    <div className='orders-menu'>
                        <CartMenu list={cartList} />
                    </div>
                    <h3 className='orders-total-description'> </h3>
                    <CartListDescription />
                    {/* <h3 className='orders-total-description-plan'>
                        Delivery <span className='order-description-item-price'>£0.00</span>
                    </h3> */}
                    <h3 className='orders-total'>
                        <span className='order-description-your-total-label'>Your total </span>
                        <span className='order-description-your-total-label'>(inc VAT):</span>
                        <span className='order-description-item-price'>£{cartTotal / 100}</span>
                    </h3>
                    <div>
                        <h1 className='orders-heading'>Delivery Date</h1>
                        <p className='orders-delivery-date'>
                            First Delivery: {calculateDeliveryDate(0)} (evening) or {calculateDeliveryDate(1)} (morning)
                        </p>
                        <p className='orders-delivery-date-desc'>Meals for Monday and Tuesday</p>
                        <p className='orders-delivery-date'>
                            Second Delivery: {calculateSecondDeliveryDate(0)} (evening) or {calculateSecondDeliveryDate(1)} (morning)
                        </p>
                        <p className='orders-delivery-date-desc'>Meals for Wednesday, Thursday and Friday</p>
                        <h1 className='orders-heading'>Customer Details</h1>
                    </div>
                    <Payment
                        cartList={cartList}
                        cartTotal={cartTotal}
                        deliveryDate={getDeliveryDateForBE()}
                        secondDeliveryDate={getSecondDeliveryDateForBE()}
                    />
                    {/* <div className='in-dev-div'>
            <h2><i class="fa-solid fa-circle-exclamation"></i> This website is currently under development.</h2>
            <h2>We will be able to take orders very soon!</h2>
            <h3>Thank you for your patience!</h3>
          </div> */}
                    <AreYouSurePopUp
                        areYouSure={areYouSurePopUp}
                        handleYes={cartRemoveAllItems}
                        handleNo={() => {
                            setAreYouSurePopUp(false);
                        }}
                        description='You&apos;e about to clear everything in your cart!'
                    />
                </div>
            )}
            <Footer />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    cartCount: selectCartItemsCount,
    cartList: selectCartItems,
    cartTotal: selectCartTotal,
});

const mapDispatchToProps = (dispatch) => ({
    cartRemoveAllItems: () => dispatch(cartRemoveAllItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
