import React from 'react';
import MenuItem from './MenuItem';
import Meal from '../../models/Meal';
// import { useDispatch } from 'react-redux';
// import { setCustomizedMenu } from '../../redux/mealplan/mealplan.action';
// import { selectMealPlanTypeById } from '../../redux/mealplan/mealplan.selector';
import './styles.css';

export const MealTime = Object.freeze({
    BREAKFAST: 'breakfast',
    LUNCH: 'lunch',
    DINNER: 'dinner',
});

const Menu = ({ openItemDetails, mealPlan, menu, itemFromPopUp }) => {
    // const dispatch = useDispatch();

    const daysOrder = [
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
        'SUNDAY',
    ];

    function sortByDay(items) {
        return items.sort((a, b) => {
            const dayIndexA = daysOrder.indexOf(a.day.toUpperCase());
            const dayIndexB = daysOrder.indexOf(b.day.toUpperCase());
            return dayIndexA - dayIndexB;
        });
    }

    const breakfastItems = sortByDay(
        menu
            .filter(item => item.mealTime === MealTime.BREAKFAST)
            .map(item => new Meal(item))
    );

    const lunchItems = sortByDay(
        menu
            .filter(item => item.mealTime === MealTime.LUNCH)
            .map(item => new Meal(item))
    );

    const dinnerItems = sortByDay(
        menu
            .filter(item => item.mealTime === MealTime.DINNER)
            .map(item => new Meal(item))
    );

    // const breakfastItems = useSelector(selectMealPlanTypeById(mealPlanItem.id, MealConsumptionType.BREAKFAST)) || [];
    // const lunchItems = useSelector(selectMealPlanTypeById(mealPlanItem.id, MealConsumptionType.LUNCH)) || [];
    // const dinnerItems = useSelector(selectMealPlanTypeById(mealPlanItem.id, MealConsumptionType.DINNER)) || [];
    //
    // const menuAlreadyCustomized = useSelector((state) => !!state.mealplan.customizedMenu[mealPlanItem.id]);
    //
    // useEffect(() => {
    //     if (!menuAlreadyCustomized) {
    //         dispatch(
    //             setCustomizedMenu(mealPlanItem.id, {
    //                 Breakfast: calculateMealOptions(mealPlanItem.meals.breakfast),
    //                 Lunch: calculateMealOptions(mealPlanItem.meals.lunch),
    //                 Dinner: calculateMealOptions(mealPlanItem.meals.dinner),
    //             })
    //         );
    //     }
    // }, [breakfastItems, lunchItems, dinnerItems, mealPlanItem, dispatch, menuAlreadyCustomized]);

    // const calculateMealOptions = (meals) => {
    //     let processedItems = [];
    //
    //     meals.forEach((item) => {
    //         if (item.family) {
    //             const familyItems = meals
    //                 .filter(
    //                     (familyItem) =>
    //                         familyItem.family === item.family &&
    //                         !processedItems.find((processedItem) => processedItem.title === familyItem.title)
    //                 )
    //                 .map((optionItem) => ({
    //                     id: optionItem.id,
    //                     title: optionItem.optionTitle,
    //                     imageUrl: optionItem.imageUrl,
    //                     preferred: optionItem.preferredOption,
    //                     calories: optionItem.calories,
    //                 }));
    //
    //             if (familyItems.length > 0) {
    //                 // Determine the preferred option
    //                 let preferredOption = familyItems.find((option) => option.preferred);
    //                 if (!preferredOption) {
    //                     // If no preferred option, set the first option as preferred
    //                     preferredOption = familyItems[0];
    //                 }
    //
    //                 const newItem = {
    //                     id: item.family,
    //                     family: item.family,
    //                     title: item.title,
    //                     description: item.description,
    //                     selectedOption: preferredOption,
    //                     options: familyItems,
    //                 };
    //                 processedItems.push(newItem);
    //             }
    //         } else {
    //             processedItems.push(item);
    //         }
    //     });
    //
    //     return processedItems;
    // };

    return (
        <main className='menu-main'>
            <div className='breakfast-div'>
                {breakfastItems.map((item) => (
                    <MenuItem
                        key={item.id}
                        item={item}
                        mealPlanId={mealPlan.id}
                        fullMenu={menu}
                        openItemDetails={openItemDetails}
                        // Temporary
                        itemFromPopUp={itemFromPopUp}
                    />
                ))}
            </div>

            <div className='lunch-div'>
                {lunchItems.map((item) => (
                    <MenuItem
                        key={item.id}
                        item={item}
                        mealPlanId={mealPlan.id}
                        fullMenu={menu}
                        openItemDetails={openItemDetails}
                        // Temporary
                        itemFromPopUp={itemFromPopUp}
                    />
                ))}
            </div>

            <div className='dinner-div'>
                {dinnerItems.map((item) => (
                    <MenuItem
                        key={item.id}
                        item={item}
                        mealPlanId={mealPlan.id}
                        fullMenu={menu}
                        openItemDetails={openItemDetails}
                        // Temporary
                        itemFromPopUp={itemFromPopUp}
                    />
                ))}
            </div>
        </main>
    );
};

export default Menu;
