import React, { useEffect } from 'react';
import { useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel.jsx';
import { cartAddItem, cartRemoveItem } from '../../redux/cart/cart.action';
import { selectCartItems, selectCartItemsCount } from '../../redux/cart/cart.selector';
import { selectMealPlanById } from '../../redux/mealplan/mealplan.selector';
import DoubleItemInCartPopUp from "../../components/Cart/DoubleItemInCartPopUp";
import MenuItemPopUpDetails from '../../components/Menu/MenuItemPopUpDetails';
import ThankYouPopUp from "../../components/Menu/ThankYouPopUp";
import Footer from '../../components/Home/Footer';
import Menu from '../../components/Menu';
import './styles.css';
import FloatingWhatsAppButton from '../../components/Home/FloatingWhatsAppButton';

const MealPlanMenu = (props) => {
    const [itemDetailsPopUp, setItemDetailsPopUp] = useState({
        mealPlanId: null,
        item: null,
        replacementMeal: null,
    });
    const [thankYouPopUp, setThankYouPopUp] = useState(null);
    const [doubleItemInCartPopUp, setDoubleItemInCartPopUp] = useState(false);

    // Temporary
    const [itemWithReplacementFromPopUp, setItemWithReplacementFromPopUp] = useState({
        itemFromPopUp: null,
        replacementItemFromPopUp: null
    });

    const customisedMealPlan = useSelector(selectMealPlanById(getMealPlan().id));
    const history = useHistory();

    useEffect(() => {
        if (!props.location.state) {
            history.push('/');
        }
    }, [props.location.state, history]);

    function openItemDetails(mealPlanId, item, replacementMeal) {
        setItemDetailsPopUp({ mealPlanId, item, replacementMeal });
    }

    function closeItemDetails(item, replacementMeal) {
        setItemWithReplacementFromPopUp({ item, replacementMeal });
        setItemDetailsPopUp({ mealPlanId: null, item: null, replacementMeal: null });
    }

    function getMealPlan() {
        return props.location.state[0] || {};
    }

    function getMenu() {
        return props.location.state[1] || [];
    }

    function getAppStatus() {
        return props.location.state[2] || { orderingEnabled: false };
    }

    function getMealPlanCalories() {
        const minCalories = getMealPlan().minCalories;
        const maxCalories = getMealPlan().maxCalories;

        if (minCalories === maxCalories) {
            return minCalories + ' Cal';
        } else {
            return minCalories + ' - ' + maxCalories + ' Cal';
        }
    }

    function addMealPlanToCart(shouldBypassAlreadyAddedCheck) {
        Mixpanel.track('Button Tapped: Add To Cart', {
            page: 'MealPlanMenu',
        });

        if (!shouldBypassAlreadyAddedCheck) {
            for (let i = 0; i < props.cartList.length; i++) {
                if (props.cartList[i].name === customisedMealPlan.name) {
                    Mixpanel.track('Operation: Display DoubleItemInCart PopUp', {
                        page: 'MealPlanMenu',
                    });
                    setDoubleItemInCartPopUp(true);
                    return;
                }
            }
        }

        const mealPlanInfo = getMealPlan();
        const mealPlanForCart = customisedMealPlan;
        mealPlanForCart.id = uuidv1();
        mealPlanForCart.type = mealPlanInfo.type;
        mealPlanForCart.title = mealPlanInfo.title;
        mealPlanForCart.price = mealPlanInfo.price;
        mealPlanForCart.imageUrl = mealPlanInfo.imageUrl;
        mealPlanForCart.minCalories = mealPlanInfo.minCalories;
        mealPlanForCart.maxCalories = mealPlanInfo.maxCalories;
        props.cartAddItem(mealPlanForCart);

        // for (let i = 0; i < menuItemsData.length; i++) {
        //     if (mealPlanForCart.mealIDs.includes(menuItemsData[i].id)) {
        //         const mealItemForCart = {
        //             ...menuItemsData[i],
        //         };

        //         mealItemForCart.id = uuidv1();
        //         props.cartAddItem(mealItemForCart);
        //     }
        // }

        Mixpanel.track('Operation: Meal plan added to cart successfully.', {
            page: 'MealPlanMenu',
        });

        setThankYouPopUp(getMealPlan());
    }

    function addOneMore() {
        setDoubleItemInCartPopUp(false);
        addMealPlanToCart(true);
    }

    // function resetMenuItemCustomization(menuItem) {
    //     delete menuItem.selectedOption;
    //     delete menuItem.selectedImage;

    //     if (menuItem.calories != null && menuItem.options != null) {
    //         if (menuItem.options.calories[0] != null) {
    //             menuItem.calories = menuItem.options.calories[0];
    //         }
    //     }
    // }

    return (
        <main>
            <div className='worked-on-div'>
                <p>
                    Meals with this icon <i className='fa-solid fa-shuffle'></i> can be customised!
                </p>
            </div>
            <div className='worked-on-div-mobile'>
                <p>
                    Tap on meals with this icon <i className='fa-solid fa-shuffle'></i> to customise them!
                </p>
            </div>
            <div className='top-buttons-div'>
                <button className='back-button' onClick={() => history.push('/')}>
                    <i className='fa fa-arrow-left'></i>Go Back
                </button>
                <div className='selected-meal-plan-details-div desktop-only'>
                    <p>Selected Meal Plan</p>
                    <h2>{getMealPlan().name}</h2>
                    <h4>{getMealPlanCalories()}</h4>
                </div>
                <button className='meal-plan-cart-button' disabled={!getAppStatus().orderingEnabled}
                        onClick={() => addMealPlanToCart(false)}>
                    <i className='fa fa-cart-plus'></i>Add To Cart
                </button>
            </div>
            <div className='selected-meal-plan-details-div mobile-only'>
                <p>Selected Meal Plan</p>
                <h2>{getMealPlan().name}</h2>
                <h4>{getMealPlanCalories()}</h4>
            </div>
            {
                getAppStatus().orderingEnabled ? null :
                    <div className='in-dev-div'>
                        <p>
                            <i className='fa-solid fa-circle-exclamation'></i> Orders through the website are temporarily disabled!
                        </p>
                        <h2>You can contact us on WhatsApp if you would like to order!</h2>
                        <h3>Thank you for your patience! ❤️</h3>
                    </div>
            }
            <Menu
                openItemDetails={openItemDetails}
                mealPlan={getMealPlan()}
                menu={getMenu()}
                itemFromPopUp={itemWithReplacementFromPopUp}
            />
            <MenuItemPopUpDetails
                closeItemDetails={closeItemDetails}
                mealPlanId={itemDetailsPopUp.mealPlanId}
                item={itemDetailsPopUp.item}
                replacementItem={itemDetailsPopUp.replacementMeal}
                fullMenu={getMenu()}
            />
            <ThankYouPopUp
                mealPlan={thankYouPopUp}
                closePopUp={() => setThankYouPopUp(null)}
                goToCart={() => history.push('/cart')}
                orderExtra={() => history.push('/extra')}
            />
            <DoubleItemInCartPopUp
                isDisplayed={doubleItemInCartPopUp}
                mealPlanName={getMealPlan().title}
                handleAddOneMore={addOneMore}
                closePopUp={() => setDoubleItemInCartPopUp(false)}
            />
            <FloatingWhatsAppButton/>
            <Footer/>
        </main>
    );
};

const mapStateToProps = createStructuredSelector({
    cartCount: selectCartItemsCount,
    cartList: selectCartItems,
});

const mapDispatchToProps = (dispatch) => ({
    cartAddItem: (item) => dispatch(cartAddItem(item)),
    cartRemoveItem: (item) => dispatch(cartRemoveItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanMenu);
