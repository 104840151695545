import React from 'react';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { auth, functions } from '../../firebaseConfig.js';
import { onAuthStateChanged, sendEmailVerification, signOut } from "firebase/auth";
import { httpsCallable } from 'firebase/functions';
import { Mixpanel } from "../../components/Mixpanel/Mixpanel";
import AreYouSurePopUp from "../../components/common/AreYouSurePopUp";

const Profile = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isResendEmailVerificationVisible, setIsResendEmailVerificationVisible] = useState(false);
    const [areYouSurePopUp, setAreYouSurePopUp] = useState(false);
    const history = useHistory();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                setIsLoading(true);
                const getUser = httpsCallable(functions, 'user');
                getUser({ userId: user.uid }).then((response) => {
                    setIsLoading(false);
                    if (user.emailVerified === false) {
                        setIsResendEmailVerificationVisible(true);
                    }
                    setUser({ id: user.uid, emailVerified: user.emailVerified, ...response.data.user });
                }).catch((error) => {
                    setIsLoading(false);
                    const errorMessage = error?.message ?? 'Unknown Error Occurred';
                    console.log(errorMessage);
                    Mixpanel.track('Operation: Failed to get user profile from Firestore', {
                        error: errorMessage,
                        page: 'Profile',
                    });
                });
            } else {
                history.push('/');
            }
        })
    }, []);

    function handleSignOut() {
        Mixpanel.track('Button Tapped: Yes, Confirm: Log out', {
            page: 'Profile',
        });
        signOut(auth);
        history.push('/');
    }

    function confirmSignOut() {
        Mixpanel.track('Button Tapped: Log out', {
            page: 'Profile',
        });
        setAreYouSurePopUp(true);
    }

    function handleViewOrders() {
        Mixpanel.track('Button Tapped: View my orders', {
            page: 'Profile',
        });
        // TODO
    }

    function handleEditProfile() {
        Mixpanel.track('Button Tapped: Edit my profile', {
            page: 'Profile',
        });
        history.push('/profile/edit', [user]);
    }

    function handleResendVerification() {
        Mixpanel.track('Button Tapped: Re-send verification email', {
            page: 'Profile',
        });
        setIsLoading(true);
        sendEmailVerification(auth.currentUser)
            .then(() => {
                setIsLoading(false);
                setIsResendEmailVerificationVisible(false);
            });
    }

    return (
        <>
            <div className='always-horizontally-aligned-div large-margin-top' style={{ animation: 'comeFromTop 0.4s' }}>
                <button className='primary-button' onClick={() => history.push('/')}>
                    <i className='fa fa-arrow-left small-padding-right'></i>Back
                </button>
                <p className='medium-title'>My Profile</p>
                {
                    isLoading ?
                        <i className='loading-circle-icon fa fa-circle-notch'></i>
                        : null
                }
            </div>

            {
                user?.postCode ?
                    <>
                        <div className='large-margin-top'>
                            <div className='horizontally-aligned-div standard-margin-bottom'>
                                <div>
                                    <p className='subtitle' style={{ animation: 'comeFromLeft 0.4s' }}>First name:</p>
                                    <p className='small-title'
                                       style={{ animation: 'comeFromLeft 0.6s' }}>{user?.firstName}</p>
                                </div>
                                <div>
                                    <p className='subtitle' style={{ animation: 'comeFromLeft 0.4s' }}>Last name:</p>
                                    <p className='small-title'
                                       style={{ animation: 'comeFromLeft 0.6s' }}>{user?.lastName}</p>
                                </div>
                                <div>
                                    <p className='subtitle' style={{ animation: 'comeFromLeft 0.4s' }}>Phone number:</p>
                                    <p className='small-title'
                                       style={{ animation: 'comeFromLeft 0.6s' }}>{user?.phone}</p>
                                </div>
                            </div>

                            <p className='subtitle' style={{ animation: 'comeFromLeft 0.6s' }}>E-mail address:</p>
                            <p className='small-title'
                               style={{ animation: 'comeFromLeft 0.8s' }}>{user?.email}</p>
                            {user?.marketingAccepted ?
                                <p className='footnote' style={{ animation: 'comeFromLeft 0.8s' }}>
                                    <i className='fa fa-check small-padding-right'></i>
                                    I have given consent to receiving marketing emails and promotional offers.
                                </p> :
                                <p className='footnote' style={{ animation: 'comeFromLeft 0.8s' }}>
                                    <i className='fa fa-ban small-padding-right'></i>
                                    I have not given consent to receiving marketing emails and promotional offers.
                                </p>
                            }
                            {user?.emailVerified ?
                                <p className='footnote standard-margin-bottom' style={{ animation: 'comeFromLeft 0.8s' }}>
                                    <i className='fa fa-check small-padding-right'></i>
                                    I have verified my e-mail address.
                                </p> :
                                <div className='standard-margin-bottom'>
                                    <p className='footnote small-margin-bottom'
                                       style={{ animation: 'comeFromLeft 0.8s' }}>
                                        <i className='fa fa-ban small-padding-right'></i>
                                        I have not verified my e-mail address. Please check your inbox or spam
                                        folder
                                        for a verification e-mail!
                                    </p>
                                    {
                                        isResendEmailVerificationVisible ?
                                            <button className='primary-button' style={{ animation: 'comeFromLeft 1s' }}
                                                    onClick={handleResendVerification}>
                                                <i className='fa fa-paper-plane small-padding-right'></i>Re-send
                                                verification email
                                            </button>
                                            :
                                            <p className='regular-text'>
                                                We have re-sent a verification email to you!
                                            </p>
                                    }
                                </div>
                            }

                            <p className='subtitle' style={{ animation: 'comeFromLeft 0.8s' }}>Delivery address:</p>
                            <p className='small-title large-margin-bottom'
                               style={{ animation: 'comeFromLeft 1s' }}>{user?.address}, {user?.postCode}, {user?.city}</p>

                            <div className='horizontally-aligned-div large-margin-bottom'>
                                <button disabled className='primary-button' style={{ animation: 'comeFromLeft 1s' }}
                                        onClick={handleViewOrders}>
                                    <i className='fa fa-list-ul small-padding-right'></i>View my orders (unavailable)
                                </button>
                                <button className='primary-button' style={{ animation: 'comeFromLeft 1s' }}
                                        onClick={handleEditProfile}>
                                    <i className='fa fa-pen-to-square small-padding-right'></i>Edit my profile
                                </button>
                            </div>

                            <button className='primary-button' style={{ animation: 'comeFromLeft 1s' }}
                                    onClick={confirmSignOut}>
                                <i className='fa fa-arrow-right-to-bracket small-padding-right'></i>Log out
                            </button>
                        </div>
                    </>
                    : null
            }

            <AreYouSurePopUp
                areYouSure={areYouSurePopUp}
                handleYes={handleSignOut}
                handleNo={() => {
                    setAreYouSurePopUp(false);
                }}
                description='You&apos;re going to log out of your account!'
            />
        </>
    );
}

export default Profile;