import React from 'react';
import { useHistory } from 'react-router';
import Footer from '../../../components/Home/Footer';
import './styles.css';

const TermsAndConditions = () => {
    const history = useHistory();

    return (
        <div className='privacy-policy-div'>
            <button className='back-button' onClick={() => history.goBack()}>
                <i className="fa fa-arrow-left"></i>Go Back
            </button>
            <h1>Terms and Conditions</h1>

            <h3>1. General</h3>
            <p>1.1 Any changes to personal information, changes in plan or wishes to pause or cancel plans must be discussed directly with the customer service team by email at catering@mokkafood.co.uk</p>
            <p>1.2 Plan prices may change and vary, at our discretion, at any time.</p>
            <p>1.3 Clients must disclose any dietary requirements and/or allergies in the appropriate section that we&apos;ve allocated during the last steps of an order.</p>
            <p>1.4 We may use any photographs for use on our social media, internal marketing and/or portfolio.</p>
            <p>1.5 Any meals you wish to skip due to dislikes or conflict of allergens, could mean doubling up on one of the existing meals and will affect the overall calorie count on the chosen plan.</p>
            <p>1.6 A meal plan cannot be exchanged for a credit note/gift card and cannot be refunded, after purchase.</p>
            <p>1.7 All meal preparation enquiries must be sent to catering@mokkafood.co.uk</p>
            <p>1.8 Allergens - food allergens and intolerances - MOKKAFOOD Ltd cannot guarantee there will not be traces of the 14 key allergens listed by the Food Standards Agency in any of the meals. MOKKAFOOD Ltd cannot ensure all allergen requests can be met.</p>
            <p>1.9 At Mokka Food we use the most fresh, raw ingredients some of which are cultivated by animals.</p>
            <p>1.10 MOKKAFOOD Ltd reserves the right to contact customer client base via email, call, text message or social media.</p>
            <p>1.11 All content included on this interactive service, such as text, graphics, button icons, images and software, is our property and is protected by UK and European copyright laws.</p>
            <p>1.12 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a Contract that is caused by events outside our reasonable control.</p>

            <h3>2. Delivery</h3>
            <p>2.1 Deliveries will be made to the address you have provided upon ordering your plan. It is your responsibility to ensure the address provided is correct. MOKKAFOOD Ltd cannot be held accountable or responsible for incorrect information provided.</p>
            <p>2.2 Our chosen couriers and customer service team will contact via SMS, Call or email if they are unable to access the delivery address. If there is no communication from the customer after we have attempted to contact you, you may be subject to a redelivery charge.</p>
            <p>2.3 Once the parcel has been delivered to your address, (or if you instruct us to leave it at your door, a safe place location or neighbour) we hold no responsibility for the safety of the parcel.</p>
            <p>2.4 If the customer is not available at the time of delivery, please ensure that either someone is available to receive the goods or otherwise the parcel will be left safe on the premises or destroyed.</p>
            <p>2.5 Factors out of our control such as weather, traffic conditions, events, public holidays, courier service issues occasionally prevent us from achieving deliveries on time. We cannot accept liability for late delivery arising from conditions beyond our control.</p>
            <p>2.6 If upon delivery you find that any products are damaged or are in an unacceptable condition, you must inform us within 24 hours and give us the opportunity to inspect the product, providing a photograph in certain circumstances may be acceptable. If, at our discretion, we find that the Product delivered is not up to standard, we will compensate the cost of the unsatisfactory item to you. We will not be responsible for any Product which is damaged or faulty through your failure to store correctly.</p>
            <p>2.7 It is your responsibility to ensure food is stored, handled and reheated correctly according to food safety regulations. MOKKAFOOD Ltd will not hold responsibility for after it has been delivered.</p>
            <p>2.8 We take every precaution to ensure our meals are delivered as practically as possible, however if at any point you are not satisfied with the quality of your goods, please contact us at catering@mokkafood.co.uk</p>
            <p>2.9 All meals should be consumed by the use by date as stated on the label.</p>

            <h3>3. Returns & Refunds</h3>
            <p>3.1 Upon accepting delivery, if you find that any products are severely damaged or are in an unacceptable condition, you must inform us immediately (up to 24 hours) after delivery. We shall begin the procedure to inspect the product and may ask for photographic evidence.</p>
            <p>3.2 At our discretion, if we find that the product delivered is not up to our usual standard, the outcome will be decided at the discretion of the team.</p>
            <p>3.3 If we should issue a refund, the refund could take between 5 – 28 days depending on the payment method and reason for the refund.</p>
            <p>3.4 We are not required to refund orders, at our discretion where we see fit.</p>

            <br />
            <br />
            <br />
            <br />
            <br />
            <Footer />
        </div>
    );
};

export default TermsAndConditions;
