import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { functions, auth } from '../../firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { Mixpanel } from "../../components/Mixpanel/Mixpanel";
import { checkPostCode } from "../../components/PaymentForm/postCodeCheck";
import LoadingPopUp from "../../components/common/LoadingPopUp";

const Registration = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        postCode: '',
        city: 'London',
        password: '',
        confirmPassword: '',
        termsAccepted: false,
        marketingAccepted: false,
    });

    const history = useHistory();

    function openTermsAndConditions() {
        Mixpanel.track('Link Tapped: Read Terms and Conditions', {
            page: 'Sign Up',
        });
        window.open("/terms-and-conditions", "_blank");
    }

    function openPrivacyPolicy() {
        Mixpanel.track('Link Tapped: Read Privacy Policy', {
            page: 'Sign Up',
        });
        window.open("/privacy-policy", "_blank");
    }

    const handleInputChange = (e) => {
        const { id, value, type, checked } = e.target;

        setErrorMessage(null);
        setFormData({
            ...formData,
            [id]: type === 'checkbox' ? checked : value
        });
    };

    function isPhoneNumberCorrect(phoneNumber) {
        const trimmedPhoneNumber = phoneNumber.trim().replaceAll(' ','');
        if (trimmedPhoneNumber.charAt(0) === '0') {
            return trimmedPhoneNumber.length >= 10 && trimmedPhoneNumber.length <= 12;
        } else if (trimmedPhoneNumber.charAt(0) === '+' && trimmedPhoneNumber.charAt(1) === '4' && trimmedPhoneNumber.charAt(2) === '4') {
            return trimmedPhoneNumber.length >= 12 && trimmedPhoneNumber.length <= 14;
        } else {
            return false;
        }
    }

    async function isFormCompleted() {
        if (formData.email === '') {
            setErrorMessage('Empty Field: E-mail address');
            return false;
        }

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(formData.email)) {
            setErrorMessage('Invalid email address. Could you please check to make sure it is correct?');
            return false;
        }

        if (formData.password.length < 8) {
            setErrorMessage('Your password must be at least 8 characters long.');
            return false;
        }

        if (formData.password === '') {
            setErrorMessage('Empty Field: Password');
            return false;
        }

        if (formData.confirmPassword === '') {
            setErrorMessage('Empty Field: Confirm password');
            return false;
        }

        if (formData.password !== formData.confirmPassword) {
            setErrorMessage(`Your passwords don't match.`);
            return false;
        }

        if (formData.firstName === '') {
            setErrorMessage('Empty Field: First name');
            return false;
        }

        if (formData.lastName === '') {
            setErrorMessage('Empty Field: Last name');
            return false;
        }

        if (formData.phone === '') {
            setErrorMessage('Empty Field: Phone number');
            return false;
        }

        if (isPhoneNumberCorrect(formData.phone) === false) {
            setErrorMessage('Invalid phone number. Could you please check to make sure it is correct?');
            return false;
        }

        if (formData.address === '') {
            setErrorMessage('Empty Field: Delivery address');
            return false;
        }

        if (formData.postCode === '') {
            setErrorMessage('Empty Field: Post code');
            return false;
        }

        if (formData.termsAccepted === false) {
            setErrorMessage('Please read and accept the Terms and Conditions and Privacy Policy before proceeding.');
            return false;
        }

        if (formData.postCode.length >= 5) {
            const postCodeToCheck = formData.postCode;
            const { postCodeError, isPostCodeCensored, isPostCodeSupported } = await checkPostCode(postCodeToCheck);
            if (isPostCodeCensored) {
                setErrorMessage(`We couldn't find this post code. Could you please check that it is correct?`);
                return false;
            }
            if (postCodeError) {
                setErrorMessage(postCodeError);
                return false;
            }
            if (isPostCodeSupported) {
                return true;
            }
        } else {
            setErrorMessage(`We couldn't find this post code. Could you please check that it is correct?`);
            return false;
        }
    }

    const handleSubmit = async () => {
        if (errorMessage) {
            return;
        }

        const isReadyToProceed = await isFormCompleted();

        if (isReadyToProceed === false) {
            return;
        }

        setIsLoading(true);

        const userAuth = {
            email: formData.email,
            password: formData.password,
        };

        const newUser = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone.trim().replaceAll(' ',''),
            address: formData.address,
            postCode: formData.postCode.toUpperCase(),
            city: formData.city,
            termsAccepted: formData.termsAccepted,
            marketingAccepted: formData.marketingAccepted,
        };

        try {
            createUserWithEmailAndPassword(auth, userAuth.email, userAuth.password)
                .then((userCredential) => {
                    const register = httpsCallable(functions, 'register');
                    register({ userId: userCredential.user.uid, user: newUser }).then(() => {

                        updateProfile(auth.currentUser, {
                            displayName: `${newUser.firstName}`,
                            phoneNumber: newUser.phone,
                        }).then(() => {

                            sendEmailVerification(auth.currentUser)
                                .then(() => {
                                    setIsLoading(false);
                                    history.push('/');
                                });

                        }).catch((error) => {
                            setIsLoading(false);
                            setErrorMessage(error.message);
                        });

                    }).catch((error) => {
                        const errorMessage = error?.message ?? 'Unknown Error Occurred';

                        Mixpanel.track('Operation: Failed to register', {
                            error: errorMessage,
                            page: 'Registration',
                        });

                        setIsLoading(false);
                        setErrorMessage(errorMessage);
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    setErrorMessage(error.message);
                });
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className='registration-container'>
            <div className='always-horizontally-aligned-div large-margin-top' style={{ animation: 'comeFromTop 0.4s' }}>
                <button className='primary-button' onClick={() => history.push('/')}>
                    <i className='fa fa-arrow-left small-margin-right'></i>Back
                </button>
                <p className='medium-title'>Sign Up</p>
            </div>
            <form className='standard-form large-margin-top' style={{ animation: 'comeFromLeft 0.8s' }}>
                <p className='footnote'>You will use your email address to log into your account. We will send your
                    order details there.</p>
                <div className='standard-margin-bottom'>
                    <input
                        type='email'
                        id='email'
                        placeholder='E-mail address'
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <p className='footnote'>Your password should be at least 8 characters long.</p>
                <div className='horizontally-aligned-div-no-gap-on-mobile standard-margin-bottom'>
                    <input
                        type='password'
                        id='password'
                        placeholder='Password'
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='password'
                        id='confirmPassword'
                        placeholder='Confirm password'
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <p className='footnote'>We need this information in order to deliver your orders to you.</p>
                <div className='horizontally-aligned-div-no-gap-on-mobile'>
                    <input
                        type='text'
                        id='firstName'
                        placeholder='First name'
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='text'
                        id='lastName'
                        placeholder='Last name'
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='tel'
                        id='phone'
                        placeholder='Phone number'
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className='horizontally-aligned-div-no-gap-on-mobile standard-margin-bottom'>
                    <input
                        type='text'
                        id='address'
                        placeholder='Delivery address'
                        value={formData.address}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='text'
                        id='postCode'
                        placeholder='Post code'
                        value={formData.postCode}
                        onChange={handleInputChange}
                        required
                    />
                    <input
                        type='text'
                        id='city'
                        placeholder='City'
                        value={formData.city}
                        readOnly
                    />
                </div>
                <div className='always-horizontally-aligned-div small-margin-bottom'
                     style={{ gap: '12px', marginLeft: '12px' }}>
                    <input
                        type='checkbox'
                        id='termsAccepted'
                        checked={formData.termsAccepted}
                        onChange={handleInputChange}
                        required
                    />
                    <p className='footnote'>
                        I agree to the <button className='link-button footnote' onClick={openTermsAndConditions}> Terms
                        and
                        Conditions </button> and <button className='link-button footnote'
                                                         onClick={openPrivacyPolicy}> Privacy
                        Policy </button>.
                    </p>
                </div>
                <div className='always-horizontally-aligned-div standard-margin-bottom'
                     style={{ gap: '12px', marginLeft: '12px' }}>
                    <input
                        type='checkbox'
                        id='marketingAccepted'
                        checked={formData.marketingAccepted}
                        onChange={handleInputChange}
                        required
                    />
                    <p className='footnote'>
                        I consent to receiving marketing emails and promotional offers.
                    </p>
                </div>
            </form>
            <button className='primary-button standard-margin-top large-margin-bottom'
                    style={{ animation: 'comeFromLeft 1s' }}
                    onClick={handleSubmit}>
                <i className='fa fa-arrow-right-to-bracket small-padding-right'></i> Create my account
            </button>
            {
                errorMessage === null ? null :
                    <div className='error-message-div'>
                        <p><i className="fa-solid fa-triangle-exclamation"></i> {errorMessage}</p>
                    </div>
            }
            <LoadingPopUp isLoadingPopUpShown={isLoading}/>
        </div>
    );
};

export default Registration;
