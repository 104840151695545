import React, { useState } from 'react';
import { Mixpanel } from '../../Mixpanel/Mixpanel';
import { auth } from '../../../firebaseConfig';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import './login-popup-style.css';

const LogInPopUp = ({ closeLogInPopUp, openRegistration, logInPopUp, appStatus }) => {
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [forgotPasswordShown, setForgotPasswordShown] = useState(false);
    const [resultMessage, setResultMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    function closePopUp() {
        setPassword('');
        setEmailAddress('');
        setForgotPasswordShown(false);
        setResultMessage(null);
        closeLogInPopUp();
    }

    function openForgotPassword() {
        Mixpanel.track('Button Tapped: Forgot Password', {
            page: 'LogInPopUp',
        });
        setForgotPasswordShown(true);
    }

    function handleInputChange(e) {
        const { id, value } = e.target;
        setErrorMessage(null);
        switch (id) {
            case 'emailAddress':
                setEmailAddress(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                break;
        }
    }

    function logIn() {
        if (forgotPasswordShown) {
            Mixpanel.track('Button Tapped: Reset my password', {
                page: 'LogInPopUp',
            });
            if (emailAddress.length < 2) {
                return;
            }
            setIsLoading(true);
            sendPasswordResetEmail(auth, emailAddress)
                .then(() => {
                    setIsLoading(false);
                    setResultMessage(`Check your inbox! We've sent you an email to reset your password.`)
                })
                .catch((error) => {
                    setIsLoading(false);
                    setResultMessage(`Check your inbox! We've sent you an email to reset your password.`)
                    console.error(error);
                });
        } else {
            Mixpanel.track('Button Tapped: Log into my account', {
                page: 'LogInPopUp',
            });
            if (emailAddress.length < 2 || password.length < 2) {
                setErrorMessage('Invalid credentials. Please, try again.')
                return;
            }
            setIsLoading(true);
            signInWithEmailAndPassword(auth, emailAddress, password)
                .then(() => {
                    setIsLoading(false);
                    closePopUp();
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                    if (error.code === 'auth/invalid-credential') {
                        setErrorMessage('Invalid credentials. Please, try again.')
                    } else {
                        setErrorMessage(error.message);
                    }
                });
        }
    }

    return logInPopUp === true ? (
        <div className='modal-container'>
            <div className='modal-content-container fifty-wide'>
                <button className='modal-close-button' onClick={closePopUp}>
                    <i className='fas fa-times'></i>
                </button>
                <p className='small-title standard-margin-bottom'>{forgotPasswordShown ? "Forgot your password?" : "Log in"}</p>
                {
                    resultMessage === null ?
                        <>
                            <form className='standard-form seventy-wide' style={{ margin: 'auto' }}>
                                <input
                                    type='text'
                                    id='emailAddress'
                                    value={emailAddress}
                                    placeholder='E-mail address'
                                    onChange={handleInputChange}
                                    required
                                ></input>
                                {
                                    forgotPasswordShown ? null :
                                        <input
                                            type='password'
                                            id='password'
                                            value={password}
                                            placeholder='Password'
                                            onChange={handleInputChange}
                                            required
                                        ></input>
                                }
                            </form>
                            {
                                forgotPasswordShown ? null :
                                    <div>
                                        <button className='secondary-button' onClick={openForgotPassword}>
                                            Forgot your password?
                                        </button>
                                        {
                                            appStatus.registrationEnabled ?
                                                <button className='secondary-button' onClick={openRegistration}>
                                                    Don&apos;t have an account?
                                                </button>
                                                : null
                                        }
                                    </div>
                            }
                            {
                                isLoading ?
                                    <i className='loading-circle-icon fa fa-circle-notch'></i>
                                    :
                                    <button className='primary-button standard-margin-top standard-margin-bottom'
                                            onClick={logIn}>
                                        { forgotPasswordShown ? "Reset my password" : "Log into my account" }
                                    </button>
                            }
                            {
                                errorMessage === null ? null :
                                    <>
                                        <br/>
                                        <div className='error-message-div'>
                                            <p><i className="fa-solid fa-triangle-exclamation"></i> {errorMessage}</p>
                                        </div>
                                    </>
                            }
                        </>
                        :
                        <p className='subtitle standard-margin-bottom'>{resultMessage}</p>
                }
            </div>
        </div>
    ) : null;
};

export default LogInPopUp;
