import MenuItem from '../../MenuItem';
import React from 'react';
import './styles.css';

const MenuXtra = ({ openItemDetails, list }) => {
    return (
        <main className='menu-xtra-main'>
            {list.map((item) => (
                <MenuItem openItemDetails={openItemDetails} item={item} key={item.id} />
            ))}
        </main>
    );
};

export default MenuXtra;
