import React from 'react';
import { useHistory } from 'react-router';
import Footer from '../../../components/Home/Footer';
import './styles.css';

const PrivacyPolicy = () => {
    const history = useHistory();

    return (
        <div className='privacy-policy-div'>
            <button className='back-button' onClick={() => history.goBack()}>
                <i className="fa fa-arrow-left"></i>Go Back
            </button>
            <h1>Privacy Policy</h1>

            <h3>1. Introduction</h3>
            <p>1.1 We are committed to safeguarding the privacy of our website visitors and service users.</p>
            <p>1.2 This policy applies where we are acting as a data controller with respect to the personal data of our website visitors and service users; in other words, where we determine the purposes and means of the processing of that personal data.</p>
            <p>1.3 We will ask you to consent to our use of cookies in accordance with the terms of this policy when you first visit our website.</p>
            <p>1.4 Our website incorporates privacy controls which affect how we will process your personal data. By using the privacy controls, you can specify whether you would like to receive direct marketing communications and limit the publication of your information.</p>
            <p>1.5 In this policy, “we”, “us” and “our” refer to MOKKAFOOD Ltd.</p>

            <h3>2. Credit</h3>
            <p>2.1 This document was created in part by using a template from SEQ Legal (https://seqlegal.com). However, the document has been heavily modified from the original version.</p>

            <h3>3. How we use your personal data</h3>
            <p>3.1 In this Section 3 we have set out:</p>
            <p>(a) the general categories of personal data that we may process;</p>
            <p>(b) in the case of personal data that we did not obtain directly from you, the source and specific categories of that data;</p>
            <p>(c) the purposes for which we may process personal data; and</p>
            <p>(d) the legal bases of the processing.</p>
            <p>3.2 We may process data about your use of our website and services (“usage data“). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is Google Analytics, Google Adwords, Facebook and HotJar. The usage data may also include certain ecommerce data, including, products purchased, coupons used, checkout behavior and attribution data. No purchase information shall be gathered, e.g credit card or debit card information. Purchase information will be captured by our third party payment gateways Stripe and Paypal. This usage data may be processed for the purposes of analysing the use of the website and services. The legal basis for this processing is our legitimate interests, namely monitoring and improving our website and services.</p>
            <p>3.3 We may process your account data (“account data“). The account data may include your name, email address. The source of the account data is you or your employer. The account data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is consent.</p>
            <p>3.4 We may process your information included in your personal profile on our website (“profile data“). The profile data may include your name, address, telephone number and email address. The profile data may be processed for the purposes of enabling and monitoring your use of our website and services. The legal basis for this processing is consent.</p>
            <p>3.5 We may process your personal data that are provided in the course of the use of our services (“service data“). The service data may include delivery information (such as, when a preferred delivery time is captured), dietary requirements, macronutrient requirements. The source of the service data is you or your employer. The service data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is consent.</p>
            <p>3.6 We may process information contained in any enquiry you submit to us regarding goods and/or services (“enquiry data“). The enquiry data may be processed for the purposes of offering, marketing and selling relevant goods and/or services to you. The legal basis for this processing is consent.</p>
            <p>3.7 We may process information relating to transactions, including purchases of goods and services, that you enter into with us and/or through our website (“transaction data“).The transaction data may include your contact details and the transaction details. Credit or debit card details are processed via a third party payment gateway - Stripe. The transaction data may be processed for the purpose of supplying the purchased goods and services and keeping proper records of those transactions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests, namely our interest in the proper administration of our website and business.</p>
            <p>3.8 We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (“notification data“). The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters. The legal basis for this processing is consent.</p>
            <p>3.9 We may process information contained in or relating to any communication that you send to us (“correspondence data“). The correspondence data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business and communications with users.</p>
            <p>3.10 We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.</p>
            <p>3.11 We may process any of your personal data identified in this policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</p>
            <p>3.12 In addition to the specific purposes for which we may process your personal data set out in this Section 3, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, in order to protect your vital interests or the vital interests of another natural person.</p>
            <p>3.13 Please do not supply any other person&apos;s personal data to us, unless we prompt you to do so.</p>
            <p>3.14 Marketing Data. We occasionally use purchased profiled data from recognised accredited opt-in data suppliers for customer acquisition purposes and the data is used on a legitimate interest basis.</p>

            <h3>4. Providing your personal data to others</h3>
            <p>4.1 We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>
            <p>4.2 Financial transactions relating to our website and services are handled by our payment services provider, Stripe. We will share transaction data with our payment services provider only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. You can find information about the payment services providers&apos; privacy policies and practices at <a href='https://stripe.com/gb' target='_blank' rel='noreferrer'>https://stripe.com/gb</a>.</p>
            <p>4.3 In addition to the specific disclosures of personal data set out in this Section 4, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>

            <h3>5. Retaining and deleting personal data</h3>
            <p>5.1 This Section 5 sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</p>
            <p>5.2 Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</p>
            <p>5.3 We will retain your personal data as follows:</p>
            <p>(a) usage data will be retained until expired or by contacting us by email and requesting us to do so.</p>
            <p>(b) account data will be retained until your order is completed.</p>
            <p>(c) profile data will be retained until your order is completed.</p>
            <p>(d) service data will be retained until your order is completed.</p>
            <p>(f) enquiry data will be retained until your order is completed.</p>
            <p>(g) transaction data will be retained until expired or by contacting us by email and requesting us to do so.</p>
            <p>(h) correspondence data will be retained until expired or by contacting us by email and requesting us to do so.</p>
            <p>5.4  Notwithstanding the other provisions of this Section 6, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>

            <h3>6. Amendments</h3>
            <p>6.1 We may update this policy by publishing a new version on our website.</p>
            <p>6.2 You should check this page occasionally to ensure you are happy with any changes to this policy.</p>
            <p>6.3 We may notify you of changes to this policy by email or through a message on our website.</p>

            <h3>7. Your rights</h3>
            <p>7.1 In this Section 7, we have summarised the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.</p>
            <p>7.2 Your principal rights under data protection law are:</p>
            <p>(a) the right to access;</p>
            <p>(b) the right to rectification;</p>
            <p>(c) the right to erasure;</p>
            <p>(d) the right to restrict processing;</p>
            <p>(e) the right to object to processing;</p>
            <p>(f) the right to data portability;</p>
            <p>(g) the right to complain to a supervisory authority; and</p>
            <p>(h) the right to withdraw consent.</p>
            <p>7.3 You have the right to confirmation as to whether or not we process your personal data and, where we do, access to the personal data, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients of the personal data. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data. The first copy will be provided free of charge, but additional copies may be subject to a reasonable fee.</p>
            <p>7.4 You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes of the processing, to have any incomplete personal data about you completed.</p>
            <p>7.5 In some circumstances you have the right to the erasure of your personal data without undue delay. Those circumstances include: the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the establishment, exercise or defence of legal claims.</p>
            <p>7.6 In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are: you contest the accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes of our processing, but you require personal data for the establishment, exercise or defence of legal claims; and you have objected to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to store your personal data. However, we will only otherwise process it: with your consent; for the establishment, exercise or defence of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.</p>
            <p>7.7 You have the right to object to our processing of your personal data on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defence of legal claims.</p>
            <p>7.8 You have the right to object to our processing of your personal data for direct marketing purposes (including profiling for direct marketing purposes). If you make such an objection, we will cease to process your personal data for this purpose.</p>
            <p>7.9 You have the right to object to our processing of your personal data for scientific or historical research purposes or statistical purposes on grounds relating to your particular situation, unless the processing is necessary for the performance of a task carried out for reasons of public interest.</p>
            <p>7.10 To the extent that the legal basis for our processing of your personal data is:</p>
            <p>(a) consent; or</p>
            <p>(b) that the processing is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract, and such processing is carried out by automated means, you have the right to receive your personal data from us in a structured, commonly used and machine-readable format. However, this right does not apply where it would adversely affect the rights and freedoms of others.</p>
            <p>7.11 If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your habitual residence, your place of work or the place of the alleged infringement. In the UK, this can be done by contacting the ICO <a href='https://ico.org.uk/make-a-complaint/' target='_blank' rel='noreferrer'>https://ico.org.uk/make-a-complaint/</a>. You can contact the Data Controller at MOKKAFOOD by emailing developers@mokkafood.co.uk.</p>
            <p>7.12 To the extent that the legal basis for our processing of your personal information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</p>
            <p>7.13 You may exercise any of your rights in relation to your personal data by written notice to us in addition to the other methods specified in this Section 7.</p>

            <h3>8. About cookies</h3>
            <p>8.1 A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>
            <p>8.2 Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
            <p>8.3 Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</p>

            <h3>9. Managing cookies</h3>
            <p>10.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:</p>
            <p>(a) <a href='https://support.google.com/chrome/answer/95647?hl=en' target='_blank' rel='noreferrer'>https://support.google.com/chrome/answer/95647?hl=en</a> (Chrome);</p>
            <p>(b) <a href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences' target='_blank' rel='noreferrer'>https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a> (Firefox);</p>
            <p>(c) <a href='http://www.opera.com/help/tutorials/security/cookies/' target='_blank' rel='noreferrer'>http://www.opera.com/help/tutorials/security/cookies/</a> (Opera);</p>
            <p>(d) <a href='https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies' target='_blank' rel='noreferrer'>https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a> (Internet Explorer);</p>
            <p>(e) <a href='https://support.apple.com/kb/PH21411' target='_blank' rel='noreferrer'>https://support.apple.com/kb/PH21411</a> (Safari); and</p>
            <p>(f) <a href='https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy' target='_blank' rel='noreferrer'>https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a> (Edge).</p>
            <p>10.2 Blocking all cookies will have a negative impact upon the usability of many websites.</p>
            <p>10.3 If you block cookies, you will not be able to use all the features on our website.</p>

            <h3>11. Our details</h3>
            <p>11.1 This website is owned and operated by MOKKAFOOD LIMITED.</p>
            <p>11.2 We are registered in the U.K. under registration number 13683188, and our registered office is at 27 Brook Crescent, London, England, E4 9EP.</p>
            <p>11.3 You can contact us:</p>
            <p>(a) by post, to the postal address given above;</p>
            <p>(b) by email, catering@mokkafood.co.uk.</p>

            <h3>12. Data protection officer</h3>
            <p>12.1 Our data protection officer is contactable at; developers@mokkafood.co.uk.</p>

            <br />
            <br />
            <br />
            <br />
            <br />
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
