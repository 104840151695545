import { useState } from 'react';
import { selectCartItemsCount } from '../../../../redux/cart/cart.selector';
import { cartRemoveItem } from '../../../../redux/cart/cart.action';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { forwardRef } from 'react';
import React from 'react';
import './styles.css';
import AreYouSurePopUp from "../../../common/AreYouSurePopUp";

const CartMenu = forwardRef(({ cartCount, list, cartRemoveItem }, ref) => {
    const [areYouSurePopUp, setAreYouSurePopUp] = useState(false);
    const [itemToRemove, setItemToRemove] = useState(null);

    function areThereItemsInCart() {
        if (cartCount != null) {
            return cartCount !== 0;
        } else {
            return false;
        }
    }

    function prepareToRemoveItem(item) {
        setItemToRemove(item);
        setAreYouSurePopUp(true);
    }

    function confirmItemRemoval() {
        setAreYouSurePopUp(false);
        cartRemoveItem(itemToRemove);
        setItemToRemove(null);
    }

    const Info = (item) => {
        const minCalories = item.item.minCalories;
        const maxCalories = item.item.maxCalories;

        if (maxCalories === minCalories) {
            return (
                <p className='meal-plan-info'>
                    <span>{minCalories} Calories</span>
                </p>
            );
        } else {
            return (
                <p className='meal-plan-info'>
                    <span>
                        {minCalories} - {maxCalories} Calories
                    </span>
                </p>
            );
        }
    };

    return areThereItemsInCart() ? (
        <main className='menu-cart-main' ref={ref}>
            {list
                .filter((item) => item.type === 'meal-plan')
                .map((item, index) => (
                    <div key={item.id || index} className='meal-plan-in-cart-div'>
                        <div className='meal-plan-cart-item-div'>
                            <img src={item.imageUrl} alt='meal-plan-image'></img>
                            <div className='meal-plan-cart-item-info-div'>
                                <h1>{item.title}</h1>
                                <Info item={item} />
                            </div>
                        </div>
                        {cartCount > 1 ? (
                            <button className='delete-button-meal-plan-cart' onClick={() => prepareToRemoveItem(item)}>
                                <i className='fas fa-times'></i>
                            </button>
                        ) : null}
                    </div>
                ))}
            <AreYouSurePopUp
                areYouSure={areYouSurePopUp}
                handleYes={() => {
                    confirmItemRemoval();
                }}
                handleNo={() => {
                    setAreYouSurePopUp(false);
                }}
                description="You'e about to remove this meal plan from your cart!"
            />
        </main>
    ) : null;
});

const mapStateToProps = createStructuredSelector({
    cartCount: selectCartItemsCount,
});

const mapDispatchToProps = (dispatch) => ({
    cartRemoveItem: (item) => dispatch(cartRemoveItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartMenu);
