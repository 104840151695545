import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { updateCustomizedMenuItem } from '../../../redux/mealplan/mealplan.action';
import Meal from '../../../models/Meal';
import './styles.css';

const MenuItem = ({ item, mealPlanId, fullMenu, openItemDetails, itemFromPopUp }) => {
    const [replacementMeal, setReplacementMeal] = useState( null);

    useEffect(() => {
        if (itemFromPopUp.item?.id === item.id && itemFromPopUp.replacementMeal !== null) {
            setReplacementMeal(itemFromPopUp.replacementMeal);
        }
    }, [itemFromPopUp]);

    const handleChange = (event) => {
        const newMenuItem = fullMenu.find(
            (menuItem) => menuItem.id === event.target.value
        );
        if (newMenuItem) {
            // updateCustomizedMenuItem(mealPlanId, item.family, newMenuItem);

            const newMeal = new Meal(newMenuItem);
            setReplacementMeal(newMeal);
        }
    };

    const OptionList = () => {
        if (item.replacements.length > 0) {
            return (
                <div className='option-list-div desktop'>
                    <select value={replacementMeal?.id || ''} onChange={handleChange}>
                        {item.replacements.map((replacement, index) => (
                            <option key={index} value={replacement.id}>
                                {replacement.name}
                            </option>
                        ))}
                    </select>
                    <p>
                        <i className='fa-solid fa-shuffle'></i>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    const MobileOptionList = () => {
        if (replacementMeal != null) {
            // return (
            //     <span className='head_desc-mobile-customization'>
            //         <p className='head_desc-customization'>with:</p>
            //         <p className='head_desc-customization-title'>
            //             {replacementMeal?.name} <i className='fa-solid fa-shuffle'></i>
            //         </p>
            //     </span>
            // );
            return null;
        } else {
            return null;
        }
    };

    function getItemPhotoURL() {
        return replacementMeal?.photoURL || item.photoURL
    }

    function getItemName() {
        return replacementMeal?.name || item.name;
    }

    function getItemDescription() {
        return replacementMeal?.description || item.description;
    }

    function getItemCalories() {
        const replacementMealCalories = replacementMeal?.findConfigurationByMealPlanId(mealPlanId).calories
        const itemCalories = item.findConfigurationByMealPlanId(mealPlanId).calories
        const returnCalories = replacementMealCalories || itemCalories || '-'

        // TODO: Remove this code, or improve it
        if (returnCalories === 1) {
            return '-'
        } else {
            return returnCalories
        }
    }

    function isItemCustomizable() {
        return item.replacements.length > 0
    }

    return (
        <div className='item'>
            <div onClick={() => openItemDetails(mealPlanId, item, replacementMeal)} className='item-image-div'>
                <img className='item-image' src={getItemPhotoURL()} alt='menu-item' />
                <i className='item-meal-plan-expand-icon fa fa-up-right-and-down-left-from-center'></i>
            </div>
            <div onClick={() => openItemDetails(mealPlanId, item, replacementMeal)} className='item-head_desc'>
                <p className='head_desc-name'>{getItemName()}</p>
                {
                    isItemCustomizable() ? null :
                        <p className='head_desc-info'>{getItemDescription()}</p>
                }
            </div>
            <MobileOptionList />
            <OptionList />
            <div className='item-calories-div'>
                <p className='item-calories'>
                    <b>{getItemCalories()}</b> Calories
                </p>
                {isItemCustomizable() ? <p className='tap-to-customize'>Tap to customise</p> : null}
            </div>
            <div className='day-of-week-div'>
                <p className='day-of-week-p'>
                    <b>{item.day}</b>
                </p>
                <p className='meal-consumption-type-p'>{item.mealTime}</p>
            </div>
        </div>
    );
};

// const mapDispatchToProps = (dispatch) => ({
//     updateCustomizedMenuItem: (mealPlanId, mealType, mealFamily, updatedItem) =>
//         dispatch(updateCustomizedMenuItem(mealPlanId, mealType, mealFamily, updatedItem)),
// });
//
// export default connect(null, mapDispatchToProps)(MenuItem);

export default MenuItem;